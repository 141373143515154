import {
  Layout,
  Typography
} from 'antd'

import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeContext } from './ThemeContext';

const { Content } = Layout;
const { Title } = Typography;

export default function MyContent() {
  const themeContext = useContext(ThemeContext)
  return (
    <Content 
      className='home-layout-bg-ly-ct'
      style={{ backgroundColor: themeContext.dpc }}
      >
      <Outlet />
    </Content>
  )
}