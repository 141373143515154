import CoffIcon from '@/utils/CoffIcon';
import React, { useContext } from 'react'
import { ThemeContext } from './ThemeContext'

type TriggerType = {
  collapsed: boolean
}

export default function MyTrigger(props: TriggerType) {
  const themeContext = useContext(ThemeContext);
  
  return (
    <div 
      style={{ backgroundColor: themeContext.bgc }}
    >
      {props.collapsed 
      ?
       (<CoffIcon icon="faArrowRight"/>)
       :
       (<CoffIcon icon="faArrowLeft"/>)
      }
    </div>
  )
}
