import React, { useContext } from 'react'
import { Breadcrumb } from 'antd'
import { ThemeContext } from './ThemeContext';
import { Link, useLocation } from 'react-router-dom';
import CoffIcon from '@/utils/CoffIcon';

export default function MyBreadcrumb() {
  const themeContext = useContext(ThemeContext);
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter((i) => i);

  const breadcrumbNameMap: Record<string, string[]> = {
    '/total-data': ['地图总览', 'faMapLocationDot'],
    '/locations': ['地点管理', 'faLocationDot'],
    '/location-types': ['地点分类', 'faListOl'],
    '/analysis': ['路径规划分析', 'faRoute'],
    '/hydrogens': ['加氢站管理', 'faGasPump'],
    '/changzhi': ['长治市规划', 'faCity'],

    '/sh-lujiazui': ['上海陆家嘴中心', 'faCity'],
    '/equip2': ['设备管理', 'faFile'],
    '/equip3': ['设备测点管理', 'faFile'],

    '/equipsub1': ['智慧能源管理', 'faFile'],
    '/equipsub1/equip4': ['实时数据管理', 'faFile'],
    '/equipsub1/equip5': ['智慧终端控制', 'faFile'],
    '/equipsub1/equip6': ['数据报表', 'faFile'],
    '/equipsub1/equip7': ['数据分析', 'faFile'],

    '/equipsub2': ['设备保养检修', 'faFile'],
    '/equipsub2/equip7': ['设备保养管理', 'faFile'],
    '/equipsub2/equip8': ['设备检修管理', 'faFile'],

    '/equipsub3': ['设备巡检管理', 'faFile'],
    '/equipsub3/equip9': ['巡检记录', 'faFile'],
    '/equipsub3/equip10': ['巡检点管理', 'faFile'],
    '/equipsub3/equip11': ['巡检线管理', 'faFile'],

    '/work1': ['能源站数据', 'faChartPie'],
    '/work1/work3': ['长申潞宝综合站', 'faChartColumn'],
    '/work1/work1': ['绿地发电站', 'faChartColumn'],
    '/work1/work2': ['八字山发电站', 'faChartColumn'],

    '/worksub1': ['能源站平台', 'faChartColumn'],
    '/worksub1/work2': ['全国已知加氢站', 'faLaptopCode'],
    '/worksub1/work3': ['海德利森加氢站', 'faLaptopCode'],
    '/worksub1/work4': ['氢产业基础数据', 'faLaptopCode'],

    '/worksub2': ['定期工作', 'faFile'],
    '/worksub2/work4': ['定期切换', 'faFile'],
    '/worksub2/work5': ['定期试验', 'faFile'],
    '/worksub2/work6': ['工作模板', 'faFile'],

    '/worksub3': ['其他', 'faFile'],
    '/worksub3/work7': ['其他 1', 'faFile'],
    '/worksub3/work8': ['其他 2', 'faFile'],

    '/apps/2': ['Application2', 'faFile'],
    '/apps/1/detail': ['Detail', 'faFile'],
    '/2apps/2/detail': ['Detail', 'faFile'],
  };

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const menu = pathSnippets.length > 1 
    //这里判断是否有多个子菜单
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    console.log(`pathSnippets are:${pathSnippets}`)
    console.log(`url is:${url}`)
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>
          <CoffIcon icon={breadcrumbNameMap[url][1]}/>
          {breadcrumbNameMap[url][0]}
        </Link>
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">
        <CoffIcon icon='faHouse'/>
        首页
      </Link>
    </Breadcrumb.Item>

  ].concat(extraBreadcrumbItems);

  return (
    <div className='home-layout-bg-ly-cb'
      style={{ backgroundColor: themeContext.bgc }}
    >
      <Breadcrumb separator='>'>
        {breadcrumbItems}
      </Breadcrumb>
    </div>
  )
} 