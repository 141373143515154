import React, { useContext, useEffect, useState } from 'react'
import './index.less';
import Request, { ResponseI } from '@/utils/Request';
import { Button, Cascader, CascaderProps, Col, Drawer, Form, Tree, PaginationProps, Popconfirm, Row, Space, Table, TableProps, theme, Input } from 'antd';
import type { ColumnsType, ExpandableConfig, TableRowSelection } from 'antd/es/table/interface';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import HeaderLayout from '../Layouts/HeaderLayout';
import EditChangzhi from './EditChangzhi';

interface ChangzhiType {
  id: string;
  key: string;
  name: string;
  changzhiCode: string;
  company: string;
  changzhiAddress: string;
  changzhiAddress2: string;
  changzhiGuimo: string;
  changzhiGuimo2: string;
  changzhiCoalPower: string;
  changzhiCoalType: string;
  changzhiType: number;

}

const defaultSelectData = {
  id: '',
  key: 'key',
  name: 'name',
  changzhiCode: 'string',
  company: 'string',
  changzhiAddress: 'string',
  changzhiAddress2: 'string',
  changzhiGuimo: 'string',
  changzhiGuimo2: 'string',
  changzhiCoalPower: 'string',
  changzhiCoalType: 'string',
  changzhiType: 1
}

interface DistrictType {
  value: string;
  label: string;
  level: string;
  adCode: string;
  children?: DistrictType[];
  isLeaf?: boolean;
}

const Changzhi: React.FC = () => {

  const [form] = Form.useForm();
  const [data, setData] = useState<ChangzhiType[]>([])
  const [loading, setLoading] = useState(true);
  const [hasData, setHasData] = useState(true);
  const [ellipsis, setEllipsis] = useState(false);
  const [rowSelection, setRowSelection] = useState<TableRowSelection<ChangzhiType> | undefined>({});
  const [tableLayout, setTableLayout] = useState();
  const [expandable, setExpandable] = useState<ExpandableConfig<ChangzhiType> | undefined>(
    undefined,
  );
  const [showHeader, setShowHeader] = useState(true);
  const [bordered, setBordered] = useState(true);
  const [size, setSize] = useState<SizeType>('middle');
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [showEditPanel, setShowEditPanel] = useState(false);
  const [tabPagination, setTabPagination] = useState({});
  const { token } = theme.useToken();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [selectData, setSelectData] = useState<ChangzhiType>(defaultSelectData);
  
  const [districts, setDistricts] = useState<DistrictType[]>([]);
  const [adCode, setAdCode] = useState<string>('');
  const [districtName, setDistrictName] = useState<string>('');
  const [changzhiName, setChangzhiName] = useState<string>('');

  const onPageChange: PaginationProps['onChange'] = (page) => {
    setPageNum(page);
  };

  useEffect(() => {
    console.log('Changzhi district useEffect');
    Request('get', `districts?queryType=treenode&id=7110fc40-8f52-4b44-9150-2b994951809f`, {})
      .then((jsonRes: any) => {
        if (jsonRes && jsonRes.success) {
          const data = jsonRes.data;
          const districts = data.childDistricts;
          const res = districts.map((item: any) => {
            return {
              value: item.id,
              label: item.name,
              level: item.level,
              adCode: item.adCode,
              isLeaf: true
            }
          });
          setDistricts(res);
        }
      })
      .catch(err => console.log(err));
  }, []);
  
  useEffect(() => {
    console.log('Changzhi useEffect');
    setLoading(true);
    let url = `changzhi?pageSize=${pageSize}&pageNum=${pageNum}`;
    if (changzhiName !== '') {
      url += `&name=${changzhiName}`;
    }
    
    Request('get', url, {})
      .then((jsonRes: any) => {
        setLoading(false);
        if (jsonRes && jsonRes.success) {
          const params = jsonRes.params;
          setTabPagination({
            total: params.total,
            current: params.pageNum,
            pageSize: params.pageSize,
            showSizeChanger: false,
            onChange: onPageChange
          });
          return jsonRes.data;
        }
      })
      .then(data => {
        setHasData(data && data.length > 0);
        data.map((item: any) => item.key = item.id)
        setData(data);
      })
      .catch(err => setLoading(false));
  }
  , [pageSize, pageNum, changzhiName])

  const handleDataCreate = (newData: ChangzhiType) => {
    setShowEditPanel(false);
    setLoading(true);
    Request('get', `changzhi?pageSize=${pageSize}&pageNum=${pageNum}`, {})
      .then((jsonRes: any) => {
        setLoading(false);
        if (jsonRes && jsonRes.success) {
          const params = jsonRes.params;
          setTabPagination({
            total: params.total,
            current: params.pageNum,
            pageSize: params.pageSize,
            onChange: onPageChange
          });
          return jsonRes.data;
        }
      })
      .then(data => {
        setHasData(data && data.length > 0);
        data.map((item: any) => item.key = item.id)
        setData(data);
      })
      .catch(err => setLoading(false));
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    setRowSelection({
      ...rowSelection,
      selectedRowKeys: newSelectedRowKeys,
    });
  };

  const handleDataChange = (newData: ChangzhiType) => {
    setShowEditPanel(false);
    const res = data.map((item: any) => {
      if (item.id === newData.id) {
        newData.key = newData.id;
        return newData;
      }
      item.key = item.id;
      return item;
    });
    setData(res);
  };

  const handleCheckData = (e: ChangzhiType) => {
    
  }

  const handleDataEdit = (e: ChangzhiType) => {
    const tmpData = Object.assign({}, e);
    const arrTmp = e.changzhiCode.split('-');
    tmpData.changzhiCode = arrTmp[1];
    setSelectData(tmpData);
    setShowEditPanel(true);
  }

  const handleShowDrawer = () => {
    setSelectData(defaultSelectData);
    setShowEditPanel(true);
  };

  const handleCloseDrawer = () => {
    setShowEditPanel(false);
  };

  const handleDataDelete = (e: any) => {
    Request('delete', `changzhi/${e.id}`, {})
      .then(res => {
        if ((res as ResponseI).success) {
          const newData = data.filter((item: any) => item.id !== e.id);
          setData(newData);
        }
      })
      .catch(err => console.log('err', err));
  }

  const columns: ColumnsType<ChangzhiType> = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      align: 'center',
      render: (_r: any, _e: any, index: number) => (pageNum - 1) * pageSize + index + 1
    },
    {
      title: '地点名称',
      dataIndex: 'name',
      align: 'center'
    },
    {
      title: '地点编号',
      dataIndex: 'changzhiCode',
      width: 100
      // sorter: (a, b) => a.age - b.age,
    },
    {
      title: '归属公司',
      dataIndex: 'company',
      width: 230
    },
    {
      title: '详细地址',
      dataIndex: 'changzhiAddress2',
      width: 350
    },
    {
      title: '生产规模',
      dataIndex: 'changzhiGuimo',
      width: 80
    },
    {
      title: '生产类型',
      dataIndex: 'changzhiCoalType',
      width: 120
    },
    {
      title: '地点类型',
      dataIndex: 'changzhiType',
      width: 80,
      render: (_text: any, record: { changzhiType: number; }) => {
        return (
          <span>
            {record.changzhiType === 2 ? '煤矿' : '发电厂'}
          </span>
        )
      }
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      width: 110,
      render: (_text: any, e: any) => (
        <Space size="middle">
          <Button 
            icon={<EditOutlined />}
            size={'small'}
            onClick={() => handleCheckData(e)}
            >
            查看详情
          </Button>
          {/* <Popconfirm
            title={`确定删除这条信息吗？`}
            onConfirm={() => handleDataDelete(e)}
            okText='确定删除'
            cancelText='取消'
          >
            <Button size='small' icon={<DeleteOutlined />}>
              删除
            </Button>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];

  const tableColumns = columns.map((item) => ({ ...item, ellipsis }));

  const tableProps: TableProps<ChangzhiType> = {
    bordered,
    loading,
    size,
    expandable,
    showHeader,
    rowSelection,
    tableLayout,
  };

  const containerStyle: React.CSSProperties = {
    position: 'relative',
    height: 'calc(100vh - 122px)',
    // background: token.colorFillAlter,
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG,
  };

  const districtOnChange = (value: (string | number)[], selectedOptions: DistrictType[]) => {
    if (!selectedOptions) {
      return;
    }
    const s = selectedOptions[selectedOptions.length - 1];
    console.log(`selectedOptions: ${selectedOptions}`)
    console.log(`s: ${s}`)
    let adCode = s?.adCode;
    console.log(`s?.level: ${s?.level}`)
    switch (s?.level) {
      case 'province':
        adCode = adCode.substring(0, 2);
        break;
      case 'city':
        adCode = adCode.substring(0, 4);
        break;
      case 'district':
        adCode = adCode.substring(0, 6);
        break;
      default:
        break;
    }
    console.log(`adCode: ${adCode}`)
    setAdCode(adCode);
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  const onSearchFinish = (values: any) => {
    setPageNum(1);
    setLoading(true);
    const name = values.name;
    let url = `changzhi?pageSize=${pageSize}&pageNum=${pageNum}`;
    if (name && name !== '') {
      setDistrictName(name);
      url += `&name=${name}`;
    } else {
      setDistrictName('');
    }

    Request('get', url, {})
    .then((jsonRes: any) => {
      setLoading(false);
      if (jsonRes && jsonRes.success) {
        const params = jsonRes.params;
        setTabPagination({
          total: params.total,
          current: params.pageNum,
          pageSize: params.pageSize,
          onChange: onPageChange
        });
        return jsonRes.data;
      }
    })
    .then(data => {
      setHasData(data && data.length > 0);
      data.map((item: any) => item.key = item.id)
      setData(data);
    })
    .catch(err => setLoading(false));

  }

  return (
    <div style={containerStyle}>
      <Drawer
        title= {selectData.id === '' ? "新建地点" : "编辑地点"}
        width={'80%'}
        placement="right"
        closable={false}
        onClose={handleCloseDrawer}
        open={showEditPanel}
        getContainer={false}
        autoFocus={true}
        afterOpenChange={() => {}}
        destroyOnClose={true}
        extra={
          <Space>
            <Button onClick={handleCloseDrawer} type='text'>取消</Button>
          </Space>
        }
      >
        <EditChangzhi
          selectData={selectData}
          onFinishSubmit={selectData.id === '' ? handleDataCreate : handleDataChange}
        />
      </Drawer>
      <HeaderLayout 
        title='长治市的工业数据' 
        btnTitle='新增数据' 
        action={handleShowDrawer}
      />
      <Form
        {...formItemLayout}
        // layout='inline'
        form={form}
        name="search"
        className="components-table-demo-control-bar"
        onFinish={onSearchFinish}
        // initialValues={{ residence: ['zhejiang', 'hangzhou', 'xihu'], prefix: '86' }}
        style={{ marginBottom: 16 }}
        scrollToFirstError
      >
        <Row>
          <Col span={10}>
            <Form.Item
              name="district"
              label="选择行政区域"
            >
              <Cascader 
                options={districts}
                // loadData={loadDistrictData}
                onChange={districtOnChange}
                changeOnSelect
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="name"
              label="地点名称"
              
            >
              <Input placeholder="输入地点名称" />
            </Form.Item>
          </Col>
          <Col span={2} />
          <Col span={4}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table
        {...tableProps}
        rowSelection={{
          type: 'radio',
          selectedRowKeys,
          onChange: onSelectChange
        }}
        pagination={tabPagination}
        columns={tableColumns}
        dataSource={hasData ? data : []}
        scroll={{y:'calc(100vh - 280px)'}}
      />
    </div>
  )
}

export default Changzhi