import React from 'react'
import { Layout } from 'antd';

export default function Login() {
  const { Header, Content, Footer, Sider } = Layout;
  return (
    <>
      <Layout>
        <Header>Header</Header>
        <Layout>
          <Sider>Sider</Sider>
          <Content>Content</Content>
        </Layout>
        <Footer>Footer</Footer>
      </Layout>
    </>
  )
}