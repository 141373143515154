import './prefixScript.js';
import React, { Component } from 'react'
import AMapLoader from '@amap/amap-jsapi-loader';
import './index.less'
import InfoPanel from './InfoPanel';

class CFMap extends Component {
  map: any;
  route: any;
  // licheng1: number = 0;
  // licheng2: number = 0;
  // licheng3: number = 0;
  // licheng4: number = 0;
  // licheng5: number = 0;
  // licheng6: number = 0;
  // licheng7: number = 0;
  state = {
    mapStyle: 'amap://styles/blue',
    licheng1: 0,
    licheng2: 0,
    licheng3: 0,
    licheng4: 0,
    licheng5: 0,
    licheng6: 0,
    licheng7: 0
  };
  constructor(props: any) {
    super(props);
    this.map = {};
    this.route = {};
  }

  // dom渲染成功后进行map对象的创建
  componentDidMount() {
    AMapLoader.load({
      key: '5532c50b4512dbf1a78f3c1af6d3abb0', // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: [
        'AMap.scale', 
        'AMap.HawkEye',
        'AMap.ToolBar',
        'AMap.ControlBar',
        'AMap.Driving',
        'AMap.DragRoute'
      ],
    })
      .then(AMap => {
        this.map = new AMap.Map('container', {
          // 设置地图容器id
          viewMode: '2D', // 是否为3D地图模式
          zoom: 11, // 初始化地图级别
          center: [109.936585,40.622369], // 初始化地图中心点位置
          mapStyle: this.state.mapStyle, // 设置地图的显示样式
          features: ['bg', 'road', 'point', 'building'], // 设置地图显示的底图参考系
        });
        this.map.addControl(new AMap.ToolBar());
        this.map.addControl(new AMap.ControlBar());
        return AMap;
      })
      .then(AMap => {
        // this.setState({ licheng1: 161068 })
        // this.setState({ licheng2: 163775 })
        // this.setState({ licheng3: 198377 })
        // this.setState({ licheng4: 162565 })
        // this.setState({ licheng5: 148212 })
        // this.setState({ licheng6: 187902 })
        // this.setState({ licheng7: 186163 })
        //制氢站点
        const startLngLat = [109.898177,40.506749];
        let driving1 = new AMap.Driving({
          map: this.map,
          // panel: 'panel',
          policy: AMap.DrivingPolicy.LEAST_DISTANCE,
          showTraffic: false,
          autoFitView: true,
        }); 

        // 1号加氢站
        const endLngLat1 = [109.745244,40.558923];
        // const endLngLat1 = [116.427281, 39.903719];
        driving1.search(startLngLat, endLngLat1, (status: any, result: any) => {
            // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
            if (status === 'complete') {
              const routes = result.routes;
              if (routes && routes.length) {
                routes.forEach((route: any) => {
                  console.log('driving1 绘制驾车路线完成,路程距离', route.distance)
                  this.setState({ licheng1: route.distance })
                })
              }
            } else {
                console.log('获取驾车数据失败：' + result)
            }
        });

        //2号加氢站
        const endLngLat2 = [110.170293,40.565847];
        let driving2 = new AMap.Driving({
          map: this.map,
          policy: AMap.DrivingPolicy.LEAST_DISTANCE,
          showTraffic: false,
          autoFitView: true,
        }); 
        // 根据起终点经纬度规划驾车导航路线
        driving2.search(startLngLat, endLngLat2, (status: any, result: any) => {
            // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
            if (status === 'complete') {
              const routes = result.routes;
              if (routes && routes.length) {
                routes.forEach((route: any) => {
                  console.log('driving2 绘制驾车路线完成,路程距离', route.distance)
                  this.setState({ licheng2: route.distance })
                })
              }
            } else {
                console.log('获取驾车数据失败：' + result)
            }
        });

        //3号加氢站
        const endLngLat3 = [110.493228,40.426676];
        let driving3 = new AMap.Driving({
          map: this.map,
          policy: AMap.DrivingPolicy.LEAST_DISTANCE,
          showTraffic: false,
          autoFitView: true,
        }); 
        // 根据起终点经纬度规划驾车导航路线
        driving3.search(startLngLat, endLngLat3, (status: any, result: any) => {
            // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
            if (status === 'complete') {
              const routes = result.routes;
              if (routes && routes.length) {
                routes.forEach((route: any) => {
                  console.log('driving3 绘制驾车路线完成,路程距离', route.distance)
                  this.setState({ licheng3: route.distance })
                })
              }
            } else {
                console.log('获取驾车数据失败：' + result)
            }
        });

        //4号加氢站
        const endLngLat4 = [109.806362,40.574561];
        let driving4 = new AMap.Driving({
          map: this.map,
          policy: AMap.DrivingPolicy.LEAST_DISTANCE,
          showTraffic: false,
          autoFitView: true,
        }); 
        // 根据起终点经纬度规划驾车导航路线
        driving4.search(startLngLat, endLngLat4, (status: any, result: any) => {
            // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
            if (status === 'complete') {
              const routes = result.routes;
              if (routes && routes.length) {
                routes.forEach((route: any) => {
                  console.log('driving4 绘制驾车路线完成,路程距离', route.distance)
                  this.setState({ licheng4: route.distance })
                })
              }
            } else {
                console.log('获取驾车数据失败：' + result)
            }
        });

        //5号加氢站(备选)
        const endLngLat5 = [109.755456,40.695478];
        let driving5 = new AMap.Driving({
          map: this.map,
          policy: AMap.DrivingPolicy.LEAST_DISTANCE,
          showTraffic: false,
          autoFitView: true,
          outlineColor: '#ff0000',
        }); 
        // 根据起终点经纬度规划驾车导航路线
        driving5.search(startLngLat, endLngLat5, (status: any, result: any) => {
            // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
            if (status === 'complete') {
              const routes = result.routes;
              if (routes && routes.length) {
                routes.forEach((route: any) => {
                  console.log('driving5 绘制驾车路线完成,路程距离', route.distance)
                  this.setState({ licheng5: route.distance })
                })
              }
            } else {
                console.log('获取驾车数据失败：' + result)
            }
        });

        //6号加氢站(备选)
        const endLngLat6 = [110.395627,40.45742];
        let driving6 = new AMap.Driving({
          map: this.map,
          policy: AMap.DrivingPolicy.LEAST_DISTANCE,
          showTraffic: false,
          autoFitView: true,
          outlineColor: '#ff0000',
        }); 
        // 根据起终点经纬度规划驾车导航路线
        driving6.search(startLngLat, endLngLat6, (status: any, result: any) => {
            // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
            if (status === 'complete') {
              const routes = result.routes;
              if (routes && routes.length) {
                routes.forEach((route: any) => {
                  console.log('driving6 绘制驾车路线完成,路程距离', route.distance)
                  this.setState({ licheng6: route.distance })
                })
              }
            } else {
                console.log('获取驾车数据失败：' + result)
            }
        });

        //7号加氢站(备选)
        const endLngLat7 = [110.384795,40.527903];
        let driving7 = new AMap.Driving({
          map: this.map,
          policy: AMap.DrivingPolicy.LEAST_DISTANCE,
          showTraffic: false,
          autoFitView: true,
          outlineColor: '#ff0000',
        }); 
        // 根据起终点经纬度规划驾车导航路线
        driving7.search(startLngLat, endLngLat7, (status: any, result: any) => {
            // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
            if (status === 'complete') {
              const routes = result.routes;
              if (routes && routes.length) {
                routes.forEach((route: any) => {
                  console.log('driving7 绘制驾车路线完成,路程距离', route.distance)
                  this.setState({ licheng7: route.distance })
                })
              }
            } else {
                console.log('获取驾车数据失败：' + result)
            }
        });
        return AMap;
      })
      .then(AMap => {
        //拖拽组件
        this.map.addControl(new AMap.DragRoute());
      })
      .catch(e => {
        console.warn(e);
      });
  }

  loadMapAndRoute = () => {
    console.log('loadMapAndRoute');
   
  }

  changeMapStyle = (mapStyle: string) => {
    this.setState({
      mapStyle,
    });
    this.map.setMapStyle(mapStyle);
  };

  render() {
    return (
      <div id='CFMap'>
        <div id="container" className="map"></div>
        <InfoPanel 
          licheng1={this.state.licheng1}
          licheng2={this.state.licheng2}
          licheng3={this.state.licheng3}
          licheng4={this.state.licheng4}
          licheng5={this.state.licheng5}
          licheng6={this.state.licheng6}
          licheng7={this.state.licheng7}
        />
      </div>
    );
  }
}
// 导出地图组建类
export default CFMap;

