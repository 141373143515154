import React from 'react'
import { Button, Col, Divider, Row, Typography } from 'antd';
import './index.less';
const { Title, Paragraph, Text, Link } = Typography;

interface HeaderLayoutProps {
  title: string
  btnTitle?: string
  action?: () => void
}

export default function HeaderLayout(props: HeaderLayoutProps) {
  return (
    <div className='header-layout-title'>
      <Row>
        <Col span={22}>
        <Title level={3}>{props.title}</Title>
        </Col>
        <Col span={2}>
        <Button className='header-layout-btn' type="primary" onClick={props.action}>{props.btnTitle}</Button>
        </Col>
      </Row>
    </div>
  )
}
