import '../CFMap/prefixScript.js';
import React, { useContext, useEffect, useState } from 'react'
import './index.less';
import AMapLoader from '@amap/amap-jsapi-loader';
import { Button, Col, Row, Table, TableProps } from 'antd';
import Request from '@/utils/Request';
import { ThemeContext } from '../Home/ThemeContext';
import { Typography, Modal  } from 'antd';
import MngLocations from './MngLocations';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { ColumnsType } from 'antd/es/table';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

const { Title } = Typography;

export interface LocationType {
  id: string;
  key: string;
  name: string;
  hydrogenSiteCode: string;
  type: DataType;
  typeId: string;
  siteDirection: number;
  icon: string;
  longitude: number;
  latitude: number;
  distance?: number;
  startPoint?: boolean;
}

export interface DataType {
  id: string;
  key: string;
  name: string;
  color: string;
  typeCode: string;
  typeFrom: number;
  icon: string;
}

const defaultLocation = {
  id: '',
  key: '',
  name: '',
  hydrogenSiteCode: '',
  type: {
    id: '',
    key: '',
    name: '',
    color: '',
    typeCode: '',
    typeFrom: 5,
    icon: ''
  },
  typeId: '',
  siteDirection: 0,
  longitude: 0,
  latitude: 0,
  icon: ''
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Analysis: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [startLocation, setStartLocation] = useState<LocationType>(defaultLocation);
  const [endLocations, setEndLocations] = useState<LocationType[]>([defaultLocation]);
  const [map, setMap] = useState({});
  const themeContext = useContext(ThemeContext)
  const [openModal, setOpenModal] = useState(false);
  const [sites, setSites] = useState<LocationType[]>([]);
  const [size, setSize] = useState<SizeType>('small');
  const [showHeader, setShowHeader] = useState(false);
  const [totalNum, setTotalNum] = useState(0);
  const [calButDisabled, setCalButDisabled] = useState(true);

  useEffect(() => {
    console.log('startLocation useEffect');
    AMapLoader.load({
      key: '5532c50b4512dbf1a78f3c1af6d3abb0', // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: [
        'AMap.scale', 
        'AMap.HawkEye',
        'AMap.ToolBar',
        'AMap.ControlBar',
        'AMap.Driving',
        'AMap.DragRoute',
        'AMap.DistrictSearch',
        'AMap.Geocoder'
      ],
    })
    .then(AMap => {
      const map = new AMap.Map('analysis-map', {
        // 设置地图容器id
        viewMode: '2D', // 是否为3D地图模式
        zoom: 11, // 初始化地图级别
        center: [109.936585,40.622369], // 初始化地图中心点位置
        mapStyle: 'amap://styles/blue', // 设置地图的显示样式
        features: ['bg', 'road', 'point', 'building'], // 设置地图显示的底图参考系
      });
      map.addControl(new AMap.ToolBar());
      map.addControl(new AMap.ControlBar());
      setMap(map);
      return AMap;
    })
    .catch(e => console.warn(e))
  }, []);

  const onSiteChangStart = (e: LocationType) => {
    startLocation.startPoint = false;
    e.distance = 0;
    e.startPoint = true;
    setStartLocation(e);
    const endLocations = sites.filter((item: LocationType) => item.id !== e.id);
    setEndLocations(endLocations)
    setCalButDisabled(endLocations.length === 0);
  }

  const columns: ColumnsType<LocationType> = [
    {
      title: '地点名称',
      dataIndex: 'name',
      width: '65%',
    },
    {
      title: '距离',
      dataIndex: 'distance',
      width: '15%',
      render: (text: any, e: LocationType) => (
        e.distance ? (e.distance / 1000).toFixed(2) + 'km' : ''
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 65,
      align: 'center',
      render: (_text: any, e: LocationType) => (
        e.startPoint ? '起点' : 
        <Button type='link' onClick={()=>onSiteChangStart(e)}>设为起点</Button>
      )
    }
  ];

  const mngMapBtnClick = () => {
    setOpenModal(true);
  }

  const onModalSubmit = (data: LocationType[]) => {
    setOpenModal(false);
    setSites(data);
  }

  const tableProps: TableProps<LocationType> = {
    size,
    showHeader,
    rowSelection: undefined,
    pagination: false,
  };

  const handleAnalysis = () => {
    setLoading(true);
    AMapLoader.load({
      key: '5532c50b4512dbf1a78f3c1af6d3abb0', // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: [
        'AMap.scale', 
        'AMap.HawkEye',
        'AMap.ToolBar',
        'AMap.ControlBar',
        'AMap.Driving',
        'AMap.DragRoute'
      ],
    })
    .then(AMap => {
      if (startLocation.id !== '') {
        for (let i = 0; i < endLocations.length; i++) {
          const driving = new AMap.Driving({
            map: map,
            policy: AMap.DrivingPolicy.LEAST_DISTANCE,
            showTraffic: false,
            autoFitView: true,
          });
          const name = endLocations[i].name;
          const type = endLocations[i].type;
          const color = type.color;
          const marker = new AMap.Marker({
            position: new AMap.LngLat(endLocations[i].longitude, endLocations[i].latitude),
            anchor:'bottom-right',
            content: 
            `<div id='amap-marker'>
              ${name}
            </div>`,
          });
          (map as typeof AMap).add(marker);
          driving.search(
            new AMap.LngLat(startLocation.longitude, startLocation.latitude),
            new AMap.LngLat(endLocations[i].longitude, endLocations[i].latitude),
            function(status: any, result: any) {
              if (status === 'complete') {
                // console.log(endLocations[i].name +'与起点的距离：', result.routes[0].distance)
                endLocations[i].distance = result.routes[0].distance;
              } else {
                console.log('获取驾车数据失败：' + result)
              }
              if (i === endLocations.length - 1) {
                setLoading(false);
                const distance = endLocations.map((item: LocationType) => item.distance ? item.distance : 0).reduce((prev: number, cur: number) => prev + cur);
                setTotalNum(distance);
              }
            }
          );
        }
      }
    })
    .catch(e => console.warn(e))
  }

  const RowT = (props: RowProps) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: props['data-row-key'],
    });
  
    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
      transition,
      cursor: 'move',
      ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };
  
    return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setSites((prev) => {
        const activeIndex = prev.findIndex((i) => i.id === active.id);
        const overIndex = prev.findIndex((i) => i.id === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    }),
  );

  return (
    <div id='analysis-main'>
      <div id="analysis-map" className="map"></div>
      <div id="analysis-controller" style={{ backgroundColor: themeContext.dpc }}>
        <Row>
          <Col span={18}>
            <Title level={3}>路径分析</Title>
          </Col>
          <Col span={4}>
            <Button onClick={mngMapBtnClick} type="dashed" className="manage-map-btn">管理地点</Button>
          </Col>
        </Row>
        <div className="analysis-content">
          <Table
            {...tableProps}
            rowKey='id'
            columns={columns}
            dataSource={sites}
            scroll={{
              y:'calc(100vh - 280px)'
            }}
          />
          <br />
          <div className="analysis-result">
            {/* <Title level={4}>结果</Title> */}
            <div className="analysis-result-content">
              <div className="analysis-result-item">
                <span>总里程：</span>
                <span>{(totalNum / 1000).toFixed(2)} km</span>
              </div>
            </div>
          </div>
          <br />
          <div id="analysis-btn">
            <Button 
              onClick={handleAnalysis} 
              type="primary" 
              className="analysis-btn"
              loading={loading}
              disabled={calButDisabled}
            >
              计算路径
            </Button>
          </div>
        </div>
      </div>
      <Modal
        className="manage-map-modal"
        title="管理地点信息"
        centered
        destroyOnClose={true}
        maskClosable={false}
        closeIcon={false}
        open={openModal}
        onCancel={()=>setOpenModal(false)}
        cancelButtonProps={{ style: { display: 'none' }}}
        okButtonProps={{ style: { display: 'none' }}}
        width={1100}
      >
        <MngLocations 
          selectData={sites}
          onFinishSubmit={onModalSubmit}           
        />
      </Modal>
    </div>
  )
}
/*
 const fds = (districtDto: DistrictDto) => {
        let district: District;
        if (districtDto.districtList && districtDto.districtList.length > 0) {
          const children = [];
          for (let i = 0; i < districtDto.districtList.length; i++) {
            const child = fds(districtDto.districtList[i]);
            children.push(child);
          }
          if (districtDto.level === 'city') {
            district = {
              name: districtDto.name,
              cityCode: districtDto.citycode,
              adCode: districtDto.adcode,
              level: districtDto.level,
              longitude: districtDto.center.lng,
              latitude: districtDto.center.lat,
              children: children
            }
          } else {
            district = {
              name: districtDto.name,
              adCode: districtDto.adcode,
              level: districtDto.level,
              longitude: districtDto.center.lng,
              latitude: districtDto.center.lat,
              children: children
            }
          }
        } else {
          if (districtDto.level === 'city') {
            district = {
              name: districtDto.name,
              cityCode: districtDto.citycode,
              adCode: districtDto.adcode,
              level: districtDto.level,
              longitude: districtDto.center.lng,
              latitude: districtDto.center.lat
            }
          } else {
            district = {
              name: districtDto.name,
              adCode: districtDto.adcode,
              level: districtDto.level,
              longitude: districtDto.center.lng,
              latitude: districtDto.center.lat
            }
          }
        }
        return district;
      }
      const districtSearch = new AMap.DistrictSearch({
        level: 'country', // 关键字对应的行政区级别，country表示国家
        subdistrict: 3 // 显示下级行政区级数，1表示返回下一级行政区
      });
        
      // 搜索所有省/直辖市信息
      districtSearch.search('中国', function(status: any, result: any) {
        // 查询成功时，result即为对应的行政区信息
        // console.log(`result`, result)
        const countryOri: DistrictDto = result.districtList[0];
        const oriList: DistrictDto[] = countryOri.districtList as DistrictDto[];
        const country: District = {
          name: countryOri.name,
          adCode: countryOri.adcode,
          level: countryOri.level,
          longitude: countryOri.center.lng,
          latitude: countryOri.center.lat,
          children: oriList.map((item: any) => fds(item))
        }
        // console.log(`要请求的数据为:${JSON.stringify(country)}`);
        return country;
      })
    })
*/
export default Analysis;
