import React, { useEffect, useState } from 'react'
import Request, { ResponseI } from '@/utils/Request';
import { Button, Col, Form, Input, Row, Select, Typography } from 'antd';
import MiniMap from '../CFMap/MiniMap';
const { Text, Title } = Typography;

interface DataType {
  id: string;
  key: string;
  name: string;
  typeCode: string;
  typeFrom: number;
  icon: string;
}

interface LocationType {
  id: string;
  key: string;
  name: string;
  hydrogenSiteCode: string;
  type: DataType;
  typeId: string;
  siteDirection: number;
  icon: string;
  longitude: number;
  latitude: number;
}

interface LocationEditProps {
  selectData: LocationType
  onFinishSubmit: (data: LocationType) => void
}

export default function EditHydrogen(props: LocationEditProps) {
  const [loading, setLoading] = useState(false);
  const formData = props.selectData;
  const [types, setTypes] = useState<DataType[]>([]);
  const [coordinates, setCoordinates] = useState({
    longitude: formData.longitude,
    latitude: formData.latitude
  });

  useEffect(() => {
    Request('get', `types/typeFrom/5`, {})
      .then((jsonRes: any) => {
        if (jsonRes && jsonRes.success) {
          const data = jsonRes.data;
          setTypes(data);
        }
      })
      .catch(err => console.log(err));
  }, []);
  

  const onFinish = (values: LocationType) => {
    console.log('values', JSON.stringify(values));
    let data = Object.assign({}, values);
    data.hydrogenSiteCode = 'LOCATION-' + data.hydrogenSiteCode;
    data.longitude = coordinates.longitude;
    data.latitude = coordinates.latitude;
    console.log('onFinish data', JSON.stringify(data));

    setLoading(true);
    
    if (formData.id !== '') {
      data.id = formData.id;
      Request('patch', `/hydrogen-sites/${formData.id}`, data)
      .then(res => {
        if ((res as ResponseI).success) {
          data = (res as ResponseI).data
        }
        setLoading(false);
        props.onFinishSubmit(data);
      })
      .catch(err =>  setLoading(false));
    } else {
      Request('post', '/hydrogen-sites', data)
      .then(res => {
        if ((res as ResponseI).success) {
          data = (res as ResponseI).data
        }        
        setLoading(false);
        props.onFinishSubmit(data);
      })
      .catch(err => {
        console.log('err', err);
        setLoading(false);
      });
    }
  };
  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      layout="horizontal"
      style={{
        maxWidth: '90%',
        textAlign: 'center'
      }}
      onFinish={onFinish}
      initialValues={formData}
    >
      <Row>
        <Col span={12}>
          <Form.Item label="地点名称" name='name'>
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="地点编号" name='hydrogenSiteCode'>
            <Input
              addonBefore='LOCATION-'
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="地点方向" name='siteDirection'>
            <Select>
              <Select.Option value={0}>未定义</Select.Option>
              <Select.Option value={1}>起点</Select.Option>
              <Select.Option value={2}>终点</Select.Option>
              <Select.Option value={3}>途径点</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="地点类型" name='typeId'>
            <Select>
              {types.map((item: DataType) => (
                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="经度" name='longitude'>
            <Title level={4} type="danger">{coordinates.longitude}</Title>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="纬度" name='latitude'>
            <Title level={4} type="danger">{coordinates.latitude}</Title>
          </Form.Item>
        </Col>
      </Row>
      <div style={{
        marginLeft: '60px',
        marginBottom: '10px',
        backgroundColor: '#f5f5f5',
        minHeight: '570px',
        width: '100%'
      }}>
        <MiniMap
          longitude={formData.longitude}
          latitude={formData.latitude}
          cb={(data: any) => {
            console.log('data', data.longitude, data.latitude);
            setCoordinates(data);
          }}
        />
      </div>
      <Row>
        <Col span={12}>
          <Form.Item label="备注" name='remark'>
            <Input
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={loading}>
              {formData.id === '' ? '提交' : '保存'}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
