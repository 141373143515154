import React, { useEffect, useState } from 'react'
import { Tree, Input, Col, Row, Table, TableProps, Tag, Button, PaginationProps, Typography, Space } from 'antd';
import Request from '@/utils/Request';
import type { TableRowSelection } from 'antd/es/table/interface';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { DownOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { EventDataNode } from 'antd/es/tree';
import type { LocationType, DataType } from './index';
const { Search } = Input;
const { Title } = Typography;

interface District {
  id: string;
  name: string;
  cityCode?: string;
  adCode: string;
  level: string;
  longitude: number;
  latitude: number;
  childDistricts?: District[];
}

interface DataNode {
  title: string;
  key: string;
  level: string;
  adCode?: string;
  isLeaf?: boolean;
  children?: DataNode[];
}

export interface MngLocationsProps {
  selectData: LocationType[]
  onFinishSubmit: (data: LocationType[]) => void
}

export default function MngLocations(props: MngLocationsProps) {

  const [districts, setDistricts] = useState<DataNode[]>([]);
  const [siteData, setSiteData] = useState<LocationType[]>([])
  const [loading, setLoading] = useState(true);
  const [hasData, setHasData] = useState(true);
  const [rowSelection, setRowSelection] = useState<TableRowSelection<LocationType> | undefined>({});
  const [size, setSize] = useState<SizeType>('small');
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [tabPagination, setTabPagination] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(props.selectData.map((item: LocationType) => item.key));  
  const [adCode, setAdCode] = useState<string>('');
  const [siteName, setSiteName] = useState<string>('');
  const [selectedSites, setSelectedSites] = useState<LocationType[]>(props.selectData);
  const [selectedDistrict, setSelectedDistrict] = useState<DataNode | undefined>(undefined);

  useEffect(() => {
    Request('get', `districts?queryType=allroots`, {})
      .then((jsonRes: any) => {
        if (jsonRes && jsonRes.success) {
          const data = jsonRes.data;
          const res = data.map((item: any) => {
            return {
              key: item.id,
              title: item.name,
              level: item.level,
              adCode: item.adCode,
              isLeaf: false
            }
          });
          setDistricts(res);
        }
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    setLoading(true);
    const name = siteName;
    let url = `hydrogen-sites?pageSize=${pageSize}&pageNum=${pageNum}`;
    if (name !== '') {
      url += `&name=${name}`;
    }
    if (adCode !== '') {
      url += `&districtAdcode=${adCode}`;
    }
    Request('get', url, {})
      .then((jsonRes: any) => {
        setLoading(false);
        if (jsonRes && jsonRes.success) {
          const params = jsonRes.params;
          setTabPagination({
            total: params.total,
            current: params.pageNum,
            pageSize: params.pageSize,
            showSizeChanger: false,
            onChange: onPageChange
          });
          return jsonRes.data;
        }
      })
      .then(data => {
        setHasData(data && data.length > 0);
        data.map((item: any) => item.key = item.id)
        setSiteData(data);
      })
      .catch(err => setLoading(false));
  }, [pageSize, pageNum, siteName, adCode])

  const columns: ColumnsType<LocationType> = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      align: 'center',
      render: (r, e, index) => (pageNum - 1) * pageSize + index + 1
    },
    {
      title: '地点名称',
      dataIndex: 'name',
      align: 'center'
    },
    {
      title: '地点编号',
      dataIndex: 'hydrogenSiteCode',
      width: 110
      // sorter: (a, b) => a.age - b.age,
    },
    {
      title: '地点类型',
      dataIndex: 'type',
      width: 60,
      render: (text: any, e: any) => e.type.name
    },
    {
      title: '城市',
      dataIndex: 'district',
      width: 70,
      render: (text: any, e: any) => e.district.name
    },
    {
      title: '地址',
      dataIndex: 'remark',
      width: 200,
      render: (_text: any, e: any) => <div style={{ width: '200px',overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', wordBreak: 'break-all' }}>{e.remark}</div>
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 80,
      align: 'center',
      render: (_text: any, e: any) => (
        <Row>
          <Col span={12}>
            <Button type='link' onClick={()=>onSiteAdd(e)}>添加</Button>
          </Col>
          <Col span={12}>
            <Button type='link' onClick={()=>onSiteDel(e)}>删除</Button>
          </Col>
        </Row>
      )
    }
  ];

  const onSiteAdd = (e: any) => {
    const tmpData = Object.assign({}, e);
    const tmpArr = selectedSites;
    let findIt = selectedSites.some((item: LocationType) => item.id === tmpData.id);
    if (!findIt) {
      tmpArr.push(tmpData);
      setSelectedSites([...tmpArr]);
      const tmpKeys = tmpArr.map((item: LocationType) => item.key);
      setSelectedRowKeys(tmpKeys);
    }
  }

  const onSiteDel = (e: any) => {
    const tmpData = Object.assign({}, e);
    onTagClose(tmpData); 
  }


  const onPageChange: PaginationProps['onChange'] = (page: number) => {
    setPageNum(page);
  };

  const findNode = (node: DataNode, key: string): DataNode | undefined => {
    if (node.key === key) {
      return node;
    }
    if (node.children) {
      for (let i = 0; i < node.children.length; i++) {
        const res = findNode(node.children[i], key);
        if (res) {
          return res;
        }
      }
    }
    return undefined;
  }

  const onSelect = (keys: any, _event: any) => {
    const selectedKey = keys[0];

    let resNode = undefined;
    for (let i = 0; i < districts.length; i++) {
      if (districts[i].key === selectedKey) {
        resNode = districts[i];
        break;
      }
      const node = findNode(districts[i], selectedKey);
      if (node) {
        resNode = node;
        break;
      }
    }
    let adcode = resNode?.adCode || '';
    switch (resNode?.level) {
      case 'province':
        adcode = adcode.substring(0, 2);
        break;
      case 'city':
        adcode = adcode.substring(0, 4);
        break;
      case 'district':
        adcode = adcode.substring(0, 6);
        break;
      default:
        break;
    }
    console.log(`aft resNode.adCode: ${adcode}`)
    if (adcode !== adCode) {
      setPageNum(1);
      setAdCode(adcode);
      setSelectedDistrict(resNode);
    }
  };

  const onExpand = (selectedOptions: any) => {};

  const fds = (d: District): DataNode => {
    const children: DataNode[] = [];
    if (d.childDistricts && d.childDistricts.length > 0) {
      for (let i = 0; i < d.childDistricts.length; i++) {
        const item: DataNode = fds(d.childDistricts[i]);
        children.push(item);
      }
      return {
        key: d.id,
        title: d.name,
        level: d.level,
        adCode: d.adCode,
        children: children,
        isLeaf: false
      }
    } else {
      return {
        key: d.id,
        title: d.name,
        level: d.level,
        adCode: d.adCode,
        isLeaf: true
      }
    }
  }

  const onLoadData = (treeNode: EventDataNode<DataNode>): Promise<any> => {
    return new Promise<void>((resolve) => {
      if (treeNode.children || treeNode.level !== 'province') {
        resolve();
        return;
      }
      setTimeout(() => {
        Request('get', `districts?queryType=treenode&id=${treeNode.key}`, {})
        .then((jsonRes: any) => {
          if (jsonRes && jsonRes.success) {
            const data = jsonRes.data;
            if (data?.childDistricts?.length > 0) {
              const res = fds(data);
              for (let i = 0; i < districts.length; i++) {
                if (districts[i].key === treeNode.key) {
                  districts[i].children = res.children;
                  break;
                }
              }
              setDistricts([...districts]);
            } else {
              treeNode.isLeaf = true;
            }
          }
        });
        resolve();
      }, 200);
    })
  };

  // const selectedSitesChange = (newSelectedSites: LocationType[], pageChange: boolean) => {
  //   if (pageChange) {
  //     const realNewSelectedSites = [];
  //     for (let i = 0; i < newSelectedSites.length; i++) {
  //       let findIt = false;
  //       for (let j = 0; j < selectedSites.length; j++) {
  //         if (newSelectedSites[i].id === selectedSites[j].id) {
  //           findIt = true;
  //           break;
  //         }
  //       }
  //       if (!findIt) {
  //         realNewSelectedSites.push(newSelectedSites[i]);
  //       }
  //     }
  //     setSelectedSites([...selectedSites, ...newSelectedSites]);
  //   } else {

  //   }
  // }

  // const onTableSelectChange = (newSelectedRowKeys: React.Key[]) => {
  //   console.log('selectedRowKeys changed: ', newSelectedRowKeys);
  //   setSelectedRowKeys(newSelectedRowKeys);
  //   setRowSelection({
  //     ...rowSelection,
  //     selectedRowKeys: newSelectedRowKeys,
  //   });
  //   const newSelectedSites = siteData.filter((item: LocationType) => newSelectedRowKeys.includes(item.key));
  //   // setSelectedSites([...selectedSites, ...newSelectedSites]);
  //   newSelectedSites.map((item: LocationType) => console.log(item.name));

  // };

  const onSearchChange = (value: string) => {
    setSiteName(value);
  }; 

  const onTagClose = (item: LocationType) => {
    // console.log('onClose' + item.name);
    const tmpArr = selectedSites.filter((i: LocationType) => i.id !== item.id);
    setSelectedSites([...tmpArr]);
    const tmpKeys = tmpArr.map((i: LocationType) => i.key);
    setSelectedRowKeys(tmpKeys);
  }

  const tableProps: TableProps<LocationType> = {
    loading,
    size,
    rowSelection,
  };

  return (
    <div>
      <Row>
        <Col span={4}>
          <br />
          <Tree
            blockNode
            showLine
            height={600}
            switcherIcon={<DownOutlined />}
            loadData={onLoadData}
            onSelect={onSelect}
            onExpand={onExpand}
            treeData={districts}
          />
          <br />
        </Col>
        <Col span={20}>
          <Row>
            <Col span={8}>
              {selectedDistrict?.title ? `筛选城市：${selectedDistrict?.title}` : null} 
            </Col>
            <Col span={1}>
            </Col>
            <Col span={13}>
            <Search 
              allowClear
              style={{ marginBottom: 8 }} 
              placeholder="输入地点名称关键字" 
              onSearch={onSearchChange}
            />
            </Col>
            <Col span={1}></Col>
          </Row>
          
          <Table
            {...tableProps}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys,
              // onChange: onTableSelectChange
            }}
            pagination={tabPagination}
            columns={columns}
            dataSource={hasData ? siteData : []}
            scroll={{y:'calc(100vh - 250px)'}}
          />
        </Col>
      </Row>
      <Row>
        <Title level={5}>已选地点:</Title>
      </Row>
      <Row>
        {selectedSites.map((item: LocationType) => (
          <Tag 
            key={item.id}
            closable
            onClose={() => onTagClose(item)}
          >
            {item.name}
          </Tag>
        ))}
      </Row>
      <br />
      <Row>
        <Col span={23}></Col>
        <Col span={1}>
          <Button type='primary' onClick={() => props.onFinishSubmit(selectedSites)}>确定</Button>
        </Col>
      </Row>
    </div>
  )
}
