import React, { useEffect, useState } from 'react';
import type { PaginationProps } from 'antd';
import { Button, theme, Popconfirm, Space, Drawer, Table, ColorPicker } from 'antd';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import type { ColumnsType, TableProps } from 'antd/es/table';
import type { ExpandableConfig, TableRowSelection } from 'antd/es/table/interface';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Request, { ResponseI } from '@/utils/Request';
import HeaderLayout from '../Layouts/HeaderLayout';
import EditType from './EditType';
import CoffIcon from '@/utils/CoffIcon';
import { Color } from 'antd/es/color-picker';

export interface DataType {
  id: string;
  key: string;
  name: string;
  color: string;
  typeCode: string;
  typeFrom: number;
  icon: string;
}

const defaultSelectData = {
  id: '',
  key: '',
  color: '#ffffff',
  name: '', 
  typeCode: '',
  typeFrom: 0,
  icon: ''
}

const LocationTypes: React.FC = () => {
  const [bordered, setBordered] = useState(true);
  const [loading, setLoading] = useState(true);
  const [size, setSize] = useState<SizeType>('middle');
  const [expandable, setExpandable] = useState<ExpandableConfig<DataType> | undefined>(
    undefined,
  );
  const [showHeader, setShowHeader] = useState(true);
  const [rowSelection, setRowSelection] = useState<TableRowSelection<DataType> | undefined>({});
  const [hasData, setHasData] = useState(true);
  const [tableLayout, setTableLayout] = useState();
  const [ellipsis, setEllipsis] = useState(false);

  const [data, setData] = useState<DataType[]>([]);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [showEditPanel, setShowEditPanel] = useState(false);
  const [tabPagination, setTabPagination] = useState({});
  const { token } = theme.useToken();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [selectData, setSelectData] = useState<DataType>(defaultSelectData);
  const onPageChange: PaginationProps['onChange'] = (page) => {
    setPageNum(page);
  };

  useEffect(() => {
    console.log('LocationTypes useEffect');
    Request('get', `types?typeFrom=5&pageSize=${pageSize}&pageNum=${pageNum}`, {})
      .then((jsonRes: any) => {
        setLoading(false);
        if (jsonRes && jsonRes.success) {
          const params = jsonRes.params;
          setTabPagination({
            total: params.total,
            current: params.pageNum,
            pageSize: params.pageSize,
            onChange: onPageChange
          });
          return jsonRes.data;
        }
      })
      .then(data => {
        setHasData(data && data.length > 0);
        data.map((item: any) => item.key = item.id)
        setData(data);
      })
      .catch(err => setLoading(false));
  }
  , [pageSize, pageNum]);

  const handleDataEdit = (e: any) => {
    const tmpData = Object.assign({}, e);
    const arrTmp = e.typeCode.split('-');
    tmpData.typeCode = arrTmp[1];
    setSelectData(tmpData);
    setShowEditPanel(true);
  }

  const handleDataDelete = (e: any) => {
    Request('delete', `/types/${e.id}`, {})
      .then(res => {
        if ((res as ResponseI).success) {
          const newData = data.filter((item: any) => item.id !== e.id);
          setData(newData);
        }
      })
      .catch(err => console.log('err', err));
  }

  const handleShowDrawer = () => {
    setSelectData(defaultSelectData);
    setShowEditPanel(true);
  };

  const handleCloseDrawer = () => {
    setShowEditPanel(false);
  };

  const handleDataCreate = (newData: DataType) => {
    setShowEditPanel(false);
    setLoading(true);
    Request('get', `types?typeFrom=5&pageSize=${pageSize}&pageNum=${pageNum}`, {})
      .then((jsonRes: any) => {
        setLoading(false);
        if (jsonRes && jsonRes.success) {
          const params = jsonRes.params;
          setTabPagination({
            total: params.total,
            current: params.pageNum,
            pageSize: params.pageSize,
            onChange: onPageChange
          });
          return jsonRes.data;
        }
      })
      .then(data => {
        setHasData(data && data.length > 0);
        data.map((item: any) => item.key = item.id)
        setData(data);
      })
      .catch(err => setLoading(false));
  }

  const handleDataChange = (newData: DataType) => {
    setShowEditPanel(false);
    const res = data.map((item: any) => {
      if (item.id === newData.id) {
        newData.key = newData.id;
        return newData;
      }
      item.key = item.id;
      return item;
    });
    setData(res);
  };

  const handleColorChange = (e: DataType, c: Color) => {
    console.log(`${e.name}, ${c.toHexString()}`);
    e.color = c.toHexString();
  }

  const columns: ColumnsType<DataType> = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      align: 'center',
      render: (r, e, index) => (pageNum - 1) * pageSize + index + 1
    },
    {
      title: '类型名称',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: '类型编号',
      dataIndex: 'typeCode',
      width: 150
      // sorter: (a, b) => a.age - b.age,
    },
    {
      title: '类型标识',
      dataIndex: 'icon',
      width: 80,
      align: 'center',
      render: (_, e) => {
        let text = '普通地点';
        switch (e.icon) {
          case 'faMapPin':
            text = '普通地点';
            break;
          case 'faGasPump':
            text = '加氢站';
            break;
          case 'faSquare':
            text = '煤矿';
            break;
          case 'faIndustry':
            text = '化工厂';
            break;
          case 'faHammer':
            text = '钢厂';
            break;
          case 'faFireFlameSimple':
            text = '制氢点';
            break;
          default:
            break;
        }
        return (
        <>
          <CoffIcon icon={`${e.icon}`} />
          {text}
        </>
      )}
    },
    {
      title: '识别色',
      dataIndex: 'color',
      width: 120,
      render: (text) => {
        return (
          <ColorPicker disabled value={text??'#00FFFF'}/>
        )
      }
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: '25%'
    },
    {
      title: '操作',
      key: 'action',
      width: 150,
      render: (text, e) => (
        <Space size="middle">
          <Button 
            icon={<EditOutlined />}
            size={'small'}
            onClick={() => handleDataEdit(e)}
            >
            编辑
          </Button>
          <Popconfirm
            title={`确定删除这条信息吗？`}
            onConfirm={() => handleDataDelete(e)}
            okText='确定删除'
            cancelText='取消'
          >
            <Button size='small' icon={<DeleteOutlined />}>
              删除
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const tableColumns = columns.map((item) => ({ ...item, ellipsis }));

  const tableProps: TableProps<DataType> = {
    bordered,
    loading,
    size,
    expandable,
    showHeader,
    rowSelection,
    tableLayout,
  };

  const containerStyle: React.CSSProperties = {
    position: 'relative',
    height: 'calc(100vh - 122px)',
    // background: token.colorFillAlter,
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG,
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    setRowSelection({
      ...rowSelection,
      selectedRowKeys: newSelectedRowKeys,
    });
  };

  return (
    <div style={containerStyle}>
       <Drawer
        title= {selectData.id === '' ? "新建地点分类" : "编辑地点分类"}
        width={500}
        placement="right"
        closable={false}
        onClose={handleCloseDrawer}
        open={showEditPanel}
        getContainer={false}
        autoFocus={true}
        afterOpenChange={() => {}}
        destroyOnClose={true}
        extra={
          <Space>
            <Button onClick={handleCloseDrawer} type='text'>取消</Button>
          </Space>
        }
      >
        <EditType 
          selectData={selectData}
          onFinishSubmit={selectData.id === '' ? handleDataCreate : handleDataChange}
        />
      </Drawer>
      <HeaderLayout
        title="对你想管理的地点进行分类"
        btnTitle='新建地点分类'
        action={handleShowDrawer}
      />
      <Table
        {...tableProps}
        rowSelection={{
          type: 'radio',
          selectedRowKeys,
          onChange: onSelectChange
        }}
        pagination={tabPagination}
        columns={tableColumns}
        dataSource={hasData ? data : []}
        scroll={{y:'calc(100vh - 280px)'}}
      />
    </div>
  );
};

export default LocationTypes;