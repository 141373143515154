import axios from 'axios';

export type ResponseI = {
  code: number;
  data: any;
  message: string;
  success: boolean;
};

axios.defaults.timeout = 10000;
axios.defaults.baseURL = 'https://ai.zhou-jia.com/api/';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

/*
  http request interceptor
*/
// axios.interceptors.request.use(
//   config => {
//     config.data = JSON.stringify(config.data);
//     config.headers.common = {
//       Accept: 'application/json',
//       'Content-Type': 'application/json'
//     };
//     return config;
//   },
//   error => {
//     return Promise.reject(error);
//   }
// );

/*
  http response interceptor
*/
axios.interceptors.response.use(
  response => {
    if (response.status === 403) {
      console.warn('token过期');
    }
    return response;
  },
  error => {
    // console.error('请求出错:', error);
  }
);

export type Method = 'get' | 'post' | 'patch' | 'delete' | 'put' | 'upload';

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get(url: string, params = {}): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url: string, data = {}): Promise<any> {
  return new Promise((resolve, reject) => {
    const jsonData = JSON.stringify(data);
    console.log(`post data is: `, jsonData);
    axios.post(url, jsonData).then(
      response => {
        //关闭进度条
        resolve(response.data);
      },
      err => {
        reject(err);
      }
    );
  });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url: string, data = {}) {
  return new Promise((resolve, reject) => {
    const jsonData = JSON.stringify(data);
    console.log(`data is: `, jsonData);
    axios.patch(url, jsonData).then(
      response => {
        resolve(response.data);
      },
      err => {
        reject(err);
      }
    );
  });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url: string, data = {}) {
  return new Promise((resolve, reject) => {
    const jsonData = JSON.stringify(data);
    // console.log(`data is: `, jsonData);
    axios.put(url, jsonData).then(
      response => {
        resolve(response.data);
      },
      err => {
        reject(err);
      }
    );
  });
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function remove(url: string, data = {}) {
  return new Promise((resolve, reject) => {
    axios.delete(url, data).then(
      response => {
        resolve(response.data);
      },
      err => {
        reject(err);
      }
    );
  });
}

//统一接口处理，返回数据
export default function (method: Method, url: string, param = {}) {
  return new Promise((resolve, reject) => {
    switch (method) {
      case 'get':
        console.log('begin a get request,and url:', url);
        get(url, param)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.log('get request GET failed.', error);
            reject(error);
          });
        break;
      case 'post':
        post(url, param)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.log('get request POST failed.', error);
            reject(error);
          });
        break;
      case 'patch':
        patch(url, param)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.log('get request PATCH failed.', error);
            reject(error);
          });
        break;
      case 'delete':
        remove(url, param)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.log('get request DELETE failed.', error);
            reject(error);
          });
        break;
      case 'put':
        put(url, param)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.log('get request PUT failed.', error);
            reject(error);
          });
        break;
      case 'upload':
        put(url, param)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.log('get request PUT failed.', error);
            reject(error);
          });
        break;
      default:
        break;
    }
  });
}
