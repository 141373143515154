import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import CFMap from './pages/CFMap';
import TotalData from './pages/TotalData';
import Locations from './pages/Locations';
import LocationTypes from './pages/LocationTypes';
import Analysis from './pages/Analysis';
import FramePage from './pages/FramePage';
import SHCity from './pages/SHCity';
import Hydrogen from './pages/Hydrogens';
import Monitor from './pages/Monitor';
import Changzhi from './pages/Changzhi';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<Login />} />

        <Route path='/' element={<Home />} >
          <Route path='total-data' element={<TotalData />} />
          <Route path='cfmap' element={<CFMap />} />
          <Route path='locations' element={<Locations />} />
          <Route path='location-types' element={<LocationTypes />} />
          <Route path='analysis' element={<Analysis />} />
          <Route path='sh-lujiazui' element={<SHCity />} />
          <Route path='hydrogens' element={<Hydrogen />} />
          <Route path='equip3' element={<NotFound />} />
          {/* <Route path='equipsub1' element={<Monitor1 />} /> */}
          <Route path='equipsub1/equip4' element={<Monitor />} />
          <Route path='equipsub1/equip5' element={<NotFound />} />
          <Route path='equipsub1/equip6' element={<NotFound />} />
          <Route path='equipsub2' element={<NotFound />} />
          <Route path='equipsub2/equip7' element={<NotFound />} />
          <Route path='equipsub2/equip8' element={<NotFound />} />
          <Route path='equipsub3' element={<NotFound />} />
          <Route path='equipsub3/equip9' element={<NotFound />} />
          <Route path='equipsub3/equip10' element={<NotFound />} />
          <Route path='equipsub3/equip11' element={<NotFound />} />
          <Route path='work1' element={<NotFound />} />
          <Route path='work1/work1' element={<FramePage 
            src='https://www.bowell.com/share?code=iqyAji'
          />} />
          <Route path='work1/work2' element={<FramePage
            src='https://datav.aliyuncs.com/share/page/4a6e64207519d0e8e62eea233b6c6b0a'
          />} />
          <Route path='/work1/work3' element={<FramePage
            src='https://lubao.zhou-jia.com/'
          />} />
          <Route path='worksub1' element={<NotFound />} />
          <Route path='worksub1/work2' element={<FramePage 
            src='https://datav.aliyuncs.com/share/3dc767368556a902a7679536e2e9787c'
          />} />
          <Route path='worksub1/work3' element={<FramePage 
            src='https://datav.aliyun.com/share/ecec6142b3ea61c62ac1e9a5496a3b0f'
          />} />
          <Route path='worksub1/work4' element={<FramePage 
            src='https://datav.aliyun.com/share/ca17420da1cdd0f0d16fc72663ac73e6'
          />} />
          <Route path='worksub2' element={<NotFound />} />
          <Route path='worksub2/work4' element={<NotFound />} />
          <Route path='worksub2/work5' element={<NotFound />} />
          <Route path='worksub2/work6' element={<NotFound />} />
          <Route path='worksub3' element={<NotFound />} />
          <Route path='worksub3/work7' element={<NotFound />} />
          <Route path='worksub3/work8' element={<NotFound />} />
          <Route path='changzhi' element={<Changzhi />} />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
