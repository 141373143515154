import CoffIcon from '@/utils/CoffIcon';
import { Menu, MenuProps, Switch, Typography } from 'antd'
import React, { useContext } from 'react'
import { ThemeContext } from './ThemeContext'

import { css, jsx } from '@emotion/react'

import styled, { CSSObject } from '@emotion/styled'

type MenuItem = Required<MenuProps>['items'][number];


const { Text } = Typography



function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  type?: 'group',
): MenuItem {
  return {
    icon,
    type,
    label,
  } as MenuItem;
}

interface SettingPropsType {
  changeDarkTheme: (checked: boolean, event: React.MouseEvent<HTMLButtonElement>) => void
}

export default function MySettingModel(props: SettingPropsType) {
  const themeContext = useContext(ThemeContext)

  const items: MenuProps['items'] = [
    getItem('用户设置', '1', <CoffIcon icon='faUser' type='far'/>),
    getItem('系统设置', '2', <CoffIcon icon='faGear' />),
    getItem('退出系统', '3', <CoffIcon icon='faArrowRightFromBracket' />),
  ];

  const onChangeTheme = (checked: boolean, event: React.MouseEvent<HTMLButtonElement>) => {
    props.changeDarkTheme(checked, event)
  }

  const onClick = () => {

  }

  const base: CSSObject = {
    backgroundColor: `${themeContext.bgc}`,
    position: 'absolute',
    top: '100%',
    right: '100%',
    width: '60px',
    height: '17px',
    marginTop: '-6px',
    marginRight: '-60px',
    '&:hover': {
      backgroundColor: `${themeContext.mhvc}`
    }
  }

  const Container = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 40px;
    border-radius: 8px;
    margin: 4px 4px 0 4px;
    &:hover {
      background-color: ${themeContext.mhvc};
    }
  `

  return (
    <>
      <span
        style={{
          backgroundColor: themeContext.hvc,
          position: 'absolute',
          top: '100%',
          right: '100%',
          width: '60px',
          height: '17px',
          marginTop: '-6px',
          marginRight: '-60px',
        }}
      />
      <div
        style={{
          backgroundColor: themeContext.hvc,
          position: 'absolute',
          top: '100%',
          right: '100%',
          width: '200px',
          height: '230px',
          // marginTop: '5px',
          marginRight: '-59px',
          borderRadius: '6px',
          transitionDelay: '0.3s',
          boxShadow: '0 2px 3px rgba(128, 128, 128, 0.5)'
        }}
      >

        <Container >
          <span
          style={{
            paddingLeft: '5px'
          }}
          >
          <CoffIcon icon='faCircleHalfStroke'/>
          <Text>
            暗色模式
          </Text>
          </span>
          
          <Switch
            onChange={onChangeTheme}
            checked={themeContext.key === 'dark'}
          ></Switch>
        </Container>

        <Menu
          style={{
            backgroundColor: themeContext.hvc,
            borderInlineEnd: '0px solid rgba(253, 253, 253, 0.12)',
            borderTopLeftRadius: '8px'
          }}
          onClick={onClick}
          selectable={false}
          mode="inline"
          items={items}
        />
      </div>
    </>
  )
}
