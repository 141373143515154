import React from 'react'

interface ChangzhiType {
  id: string;
  key: string;
  name: string;
  changzhiCode: string;
  company: string;
  changzhiAddress: string;
  changzhiAddress2: string;
  changzhiGuimo: string;
  changzhiGuimo2: string;
  changzhiCoalPower: string;
  changzhiCoalType: string;
  changzhiType: number;

}

interface DistrictType {
  value: string;
  label: string;
  level: string;
  adCode: string;
  children?: DistrictType[];
  isLeaf?: boolean;
}

interface ChangzhiEditProps {
  selectData: ChangzhiType
  onFinishSubmit: (data: ChangzhiType) => void
}

export default function EditChangzhi(props: ChangzhiEditProps) {
  return (
    <div>
      EditChangzhi
    </div>
  )
}
