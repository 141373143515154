import React, { useEffect, useState } from 'react'
import Request, { ResponseI } from '@/utils/Request';
import { Button, Cascader, CascaderProps, Col, Drawer, Form, Tree, PaginationProps, Popconfirm, Row, Space, Table, TableProps, theme, Input } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import type { ExpandableConfig, TableRowSelection } from 'antd/es/table/interface';
import HeaderLayout from '../Layouts/HeaderLayout';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import EditLocation from './EditLocation';

export interface DataType {
  id: string;
  key: string;
  name: string;
  typeCode: string;
  typeFrom: number;
  icon: string;
}

interface LocationType {
  id: string;
  key: string;
  name: string;
  hydrogenSiteCode: string;
  type: DataType;
  typeId: string;
  siteDirection: number;
  icon: string;
  longitude: number;
  latitude: number;
}

const defaultSelectData = {
  id: '',
  key: '',
  name: '', 
  hydrogenSiteCode: '',
  type: {
    id: '',
    key: '',
    name: '',
    typeCode: '',
    typeFrom: 0,
    icon: ''
  },
  typeId: '',
  siteDirection: 0,
  longitude: 0,
  latitude: 0,
  icon: ''
}

// const transformDirection = (direction: number) => {
//   switch (direction) {
//     case 0:
//       return '未定义';
//     case 1:
//       return '起点';
//     case 2:
//       return '终点';
//     case 3:
//       return '途径点';
//     default:
//       return '未知';
//   }
// }

interface District {
  id: string;
  name: string;
  cityCode?: string;
  adCode: string;
  level: string;
  longitude: number;
  latitude: number;
  childDistricts?: District[];
}

interface DistrictType {
  value: string;
  label: string;
  level: string;
  adCode: string;
  children?: DistrictType[];
  isLeaf?: boolean;
}

const Locations: React.FC = () =>  {
  const [form] = Form.useForm();
  const [data, setData] = useState<LocationType[]>([])
  const [loading, setLoading] = useState(true);
  const [hasData, setHasData] = useState(true);
  const [ellipsis, setEllipsis] = useState(false);
  const [rowSelection, setRowSelection] = useState<TableRowSelection<LocationType> | undefined>({});
  const [tableLayout, setTableLayout] = useState();
  const [expandable, setExpandable] = useState<ExpandableConfig<LocationType> | undefined>(
    undefined,
  );
  const [showHeader, setShowHeader] = useState(true);
  const [bordered, setBordered] = useState(true);
  const [size, setSize] = useState<SizeType>('middle');
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [showEditPanel, setShowEditPanel] = useState(false);
  const [tabPagination, setTabPagination] = useState({});
  const { token } = theme.useToken();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [selectData, setSelectData] = useState<LocationType>(defaultSelectData);
  
  const [districts, setDistricts] = useState<DistrictType[]>([]);
  const [adCode, setAdCode] = useState<string>('');
  const [districtName, setDistrictName] = useState<string>('');

  const onPageChange: PaginationProps['onChange'] = (page) => {
    setPageNum(page);
  };

  useEffect(() => {
    console.log('District useEffect');
    Request('get', `districts?queryType=allroots`, {})
      .then((jsonRes: any) => {
        if (jsonRes && jsonRes.success) {
          const data = jsonRes.data;
          const res = data.map((item: any) => {
            return {
              value: item.id,
              label: item.name,
              level: item.level,
              adCode: item.adCode,
              isLeaf: false
            }
          });
          const shanxiOther = res.filter((item: any) => item.adCode !== '140000');
          // console.log(`shanxiOther: ${shanxiOther.length}`)
          const shanxi = res.filter((item: any) => item.adCode === '140000');
          // console.log(`shanxi: ${shanxi.length}`)
          setDistricts([...shanxi, ...shanxiOther]);
        }
      })
      .catch(err => console.log(err));
  }, []);
  
  useEffect(() => {
    console.log('Locations useEffect');
    setLoading(true);
    const name = districtName;
    let url = `hydrogen-sites?pageSize=${pageSize}&pageNum=${pageNum}`;
    if (name !== '') {
      url += `&name=${name}`;
    }
    if (adCode !== '') {
      url += `&districtAdcode=${adCode}`;
    }
    Request('get', url, {})
      .then((jsonRes: any) => {
        setLoading(false);
        if (jsonRes && jsonRes.success) {
          const params = jsonRes.params;
          setTabPagination({
            total: params.total,
            current: params.pageNum,
            pageSize: params.pageSize,
            showSizeChanger: false,
            onChange: onPageChange
          });
          return jsonRes.data;
        }
      })
      .then(data => {
        setHasData(data && data.length > 0);
        data.map((item: any) => item.key = item.id)
        setData(data);
      })
      .catch(err => setLoading(false));
  }
  , [pageSize, pageNum])

  const handleDataEdit = (e: any) => {
    const tmpData = Object.assign({}, e);
    const arrTmp = e.hydrogenSiteCode.split('-');
    tmpData.hydrogenSiteCode = arrTmp[1];
    setSelectData(tmpData);
    setShowEditPanel(true);
  }

  const handleDataDelete = (e: any) => {
    Request('delete', `hydrogen-sites/${e.id}`, {})
      .then(res => {
        if ((res as ResponseI).success) {
          const newData = data.filter((item: any) => item.id !== e.id);
          setData(newData);
        }
      })
      .catch(err => console.log('err', err));
  }

  const handleShowDrawer = () => {
    setSelectData(defaultSelectData);
    setShowEditPanel(true);
  };

  const handleCloseDrawer = () => {
    setShowEditPanel(false);
  };

  const handleDataCreate = (newData: LocationType) => {
    setShowEditPanel(false);
    setLoading(true);
    Request('get', `hydrogen-sites?pageSize=${pageSize}&pageNum=${pageNum}`, {})
      .then((jsonRes: any) => {
        setLoading(false);
        if (jsonRes && jsonRes.success) {
          const params = jsonRes.params;
          setTabPagination({
            total: params.total,
            current: params.pageNum,
            pageSize: params.pageSize,
            onChange: onPageChange
          });
          return jsonRes.data;
        }
      })
      .then(data => {
        setHasData(data && data.length > 0);
        data.map((item: any) => item.key = item.id)
        setData(data);
      })
      .catch(err => setLoading(false));
  }

  const handleDataChange = (newData: LocationType) => {
    setShowEditPanel(false);
    const res = data.map((item: any) => {
      if (item.id === newData.id) {
        newData.key = newData.id;
        return newData;
      }
      item.key = item.id;
      return item;
    });
    setData(res);
  };

  const columns: ColumnsType<LocationType> = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      align: 'center',
      render: (r, e, index) => (pageNum - 1) * pageSize + index + 1
    },
    {
      title: '地点名称',
      dataIndex: 'name',
      align: 'center',
      width: 260
    },
    {
      title: '地点编号',
      dataIndex: 'hydrogenSiteCode',
      width: 120
      // sorter: (a, b) => a.age - b.age,
    },
    {
      title: '地点类型',
      dataIndex: 'type',
      width: 60,
      render: (text: any, e: any) => (
        <Space size="middle">
          {e.type.name}
        </Space>
      )
    },
    {
      title: '城市',
      dataIndex: 'district',
      width: 70,
      render: (text: any, e: any) => (
        <Space size="middle">
          {e.district.name}
        </Space>
      )
    },
    {
      title: '经度',
      dataIndex: 'longitude',
      width: 100
    },
    {
      title: '纬度',
      dataIndex: 'latitude',
      width: 100
    },
    {
      title: '备注',
      dataIndex: 'remark',
      ellipsis: {
        showTitle: false,
      }
    },
    {
      title: '操作',
      key: 'action',
      width: 150,
      render: (text, e) => (
        <Space size="middle">
          <Button 
            icon={<EditOutlined />}
            size={'small'}
            onClick={() => handleDataEdit(e)}
            >
            编辑
          </Button>
          <Popconfirm
            title={`确定删除这条信息吗？`}
            onConfirm={() => handleDataDelete(e)}
            okText='确定删除'
            cancelText='取消'
          >
            <Button size='small' icon={<DeleteOutlined />}>
              删除
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const tableColumns = columns.map((item) => ({ ...item, ellipsis }));

  const tableProps: TableProps<LocationType> = {
    bordered,
    loading,
    size,
    expandable,
    showHeader,
    rowSelection,
    tableLayout,
  };

  const containerStyle: React.CSSProperties = {
    position: 'relative',
    height: 'calc(100vh - 122px)',
    // background: token.colorFillAlter,
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG,
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    setRowSelection({
      ...rowSelection,
      selectedRowKeys: newSelectedRowKeys,
    });
  };

  const onSearchFinish = (values: any) => {
    setPageNum(1);
    setLoading(true);
    const name = values.name;
    let url = `hydrogen-sites?pageSize=${pageSize}&pageNum=${pageNum}`;
    if (name && name !== '') {
      setDistrictName(name);
      url += `&name=${name}`;
    } else {
      setDistrictName('');
    }
    if (adCode !== '') {
      url += `&districtAdcode=${adCode}`;
    }
    Request('get', url, {})
    .then((jsonRes: any) => {
      setLoading(false);
      if (jsonRes && jsonRes.success) {
        const params = jsonRes.params;
        setTabPagination({
          total: params.total,
          current: params.pageNum,
          pageSize: params.pageSize,
          onChange: onPageChange
        });
        return jsonRes.data;
      }
    })
    .then(data => {
      setHasData(data && data.length > 0);
      data.map((item: any) => item.key = item.id)
      setData(data);
    })
    .catch(err => setLoading(false));

  }

  const districtOnChange = (value: (string | number)[], selectedOptions: DistrictType[]) => {
    if (!selectedOptions) {
      return;
    }
    const s = selectedOptions[selectedOptions.length - 1];
    console.log(`selectedOptions: ${selectedOptions}`)
    console.log(`s: ${s}`)
    let adCode = s?.adCode;
    console.log(`s?.level: ${s?.level}`)
    switch (s?.level) {
      case 'province':
        adCode = adCode.substring(0, 2);
        break;
      case 'city':
        adCode = adCode.substring(0, 4);
        break;
      case 'district':
        adCode = adCode.substring(0, 6);
        break;
      default:
        break;
    }
    console.log(`adCode: ${adCode}`)
    setAdCode(adCode);
  }

  const fds = (d: District): DistrictType => {
    const children: DistrictType[] = [];
    if (d.childDistricts && d.childDistricts.length > 0) {
      for (let i = 0; i < d.childDistricts.length; i++) {
        const item: DistrictType = fds(d.childDistricts[i]);
        children.push(item);
      }
      return {
        value: d.id,
        label: d.name,
        level: d.level,
        adCode: d.adCode,
        children: children,
        isLeaf: false
      }
    } else {
      return {
        value: d.id,
        label: d.name,
        level: d.level,
        adCode: d.adCode,
        isLeaf: true
      }
    }
  }

  const loadDistrictData = (selectedOptions: DistrictType[]) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    if (targetOption.level !== 'province') return;
    console.log(`重新加载区域数据，此次加载的数据root节点为:${targetOption.label}`)
    Request('get', `districts?queryType=treenode&id=${targetOption.value}`, {})
      .then((jsonRes: any) => {
        if (jsonRes && jsonRes.success) {
          const data = jsonRes.data;
          if (data?.childDistricts?.length > 0) {
            const res = fds(data);
            for (let i = 0; i < districts.length; i++) {
              if (districts[i].value === targetOption.value) {
                districts[i].children = res.children;
                break;
              }
            }
            setDistricts([...districts]);
          } else {
            targetOption.isLeaf = true;
          }
        }
      })
      .catch(err => console.log(err));
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  return (
    <div style={containerStyle}>
      <Drawer
        title= {selectData.id === '' ? "新建地点" : "编辑地点"}
        width={'80%'}
        placement="right"
        closable={false}
        onClose={handleCloseDrawer}
        open={showEditPanel}
        getContainer={false}
        autoFocus={true}
        afterOpenChange={() => {}}
        destroyOnClose={true}
        extra={
          <Space>
            <Button onClick={handleCloseDrawer} type='text'>取消</Button>
          </Space>
        }
      >
        <EditLocation 
          selectData={selectData}
          onFinishSubmit={selectData.id === '' ? handleDataCreate : handleDataChange}
        />
      </Drawer>
      <HeaderLayout 
        title='地点管理' 
        btnTitle='新增地点' 
        action={handleShowDrawer}
      />
      <Form
        {...formItemLayout}
        // layout='inline'
        form={form}
        name="search"
        className="components-table-demo-control-bar"
        onFinish={onSearchFinish}
        // initialValues={{ residence: ['zhejiang', 'hangzhou', 'xihu'], prefix: '86' }}
        style={{ marginBottom: 16 }}
        scrollToFirstError
      >
        <Row>
          <Col span={10}>
            <Form.Item
              name="district"
              label="选择行政区域"
            >
              <Cascader 
                options={districts}
                loadData={loadDistrictData}
                onChange={districtOnChange}
                changeOnSelect
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="name"
              label="地点名称"
              
            >
              <Input placeholder="输入地点名称" />
            </Form.Item>
          </Col>
          <Col span={2} />
          <Col span={4}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table
        {...tableProps}
        rowSelection={{
          type: 'radio',
          selectedRowKeys,
          onChange: onSelectChange
        }}
        pagination={tabPagination}
        columns={tableColumns}
        dataSource={hasData ? data : []}
        scroll={{y:'calc(100vh - 280px)'}}
      />
    </div>
  )
}

export default Locations;
