import { Button, ColorPicker, Form, Input, Select } from 'antd';
import React, { useState } from 'react'
import Request, { ResponseI } from '@/utils/Request';
import type { DataType } from './index';
import { Color } from 'antd/es/color-picker';

// interface DataType {
//   id: string;
//   key: string;
//   name: string;
//   typeCode: string;
//   typeFrom: number;
//   icon: string;
// }

interface LocationTypeEditProps {
  selectData: DataType
  onFinishSubmit: (data: DataType) => void
}

export default function EditType(props: LocationTypeEditProps) {
  const [loading, setLoading] = useState(false);
  const formData = props.selectData;
  const [color, setColor] = useState(props.selectData.color);

  const onColorChange = (color: Color) => {
    setColor(color.toHexString());
  }

  const onFinish = (values: DataType) => {
    let data = Object.assign({}, values);
    data.typeCode = 'LOC-' + data.typeCode;
    data.color = color;
    setLoading(true);
    if (formData.id !== '') {
      data.id = formData.id;
      Request('patch', `/types/${formData.id}`, data)
      .then(res => {
        if ((res as ResponseI).success) {
          data = (res as ResponseI).data
        }
        setLoading(false);
        props.onFinishSubmit(data);
      })
      .catch(err => setLoading(false));
    } else {
      data.typeFrom = 5;
      Request('post', '/types', data)
      .then(res => {
        if ((res as ResponseI).success) {
          data = (res as ResponseI).data
        }        
        setLoading(false);
        props.onFinishSubmit(data);
      })
      .catch(err => {
        console.log('err', err);
        setLoading(false);
      });
    }
  };

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      layout="horizontal"
      style={{ 
        maxWidth: '500px',
        textAlign: 'center'
      }}
      onFinish={onFinish}
      initialValues={formData}
    >
      <Form.Item label="类型名称" name='name'>
        <Input allowClear/>
      </Form.Item>
      <Form.Item label="类型编号" name='typeCode'>
        <Input 
          addonBefore='LOC-'
          allowClear
        />
      </Form.Item>
      <Form.Item label="类型标识" name='icon'>
      <Select>
        <Select.Option value="faMapPin">普通地点</Select.Option>
        <Select.Option value="faGasPump">加氢站</Select.Option>
        <Select.Option value="faIndustry">化工厂</Select.Option>
        <Select.Option value="faSquare">煤矿</Select.Option>
        <Select.Option value="faHammer">钢厂</Select.Option>
        <Select.Option value="faFireFlameSimple">制氢点</Select.Option>
      </Select>
      </Form.Item>
      <Form.Item label="识别色" name='color'>
        <ColorPicker
          onChange={onColorChange}
        />
      </Form.Item>
      <Form.Item label="备注" name='remark'>
        <Input.TextArea allowClear/>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={loading}>
          {formData.id === '' ? '提交' : '保存'}
        </Button>
    </Form.Item>
    </Form>
  )
}
