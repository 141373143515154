import './prefixScript.js';
import React, { useEffect, useState } from 'react'
import './index.less';
import AMapLoader from '@amap/amap-jsapi-loader';
import { Button } from 'antd';

type coordinateType = {
  longitude: number;
  latitude: number;
}

type MiniMapProps = {
  longitude: number;
  latitude: number;
  cb: (data: coordinateType) => void;
}

export default function MiniMap(props: MiniMapProps) {
  const [coordinate, setCoordinate] = useState<coordinateType>({
    longitude: props.longitude !== 0  ? props.longitude : 113.189007,
    latitude: props.latitude !== 0  ? props.latitude : 36.224153
  });

  useEffect(() => {
    console.log('MiniMap useEffect');
    AMapLoader.load({
      key: '5532c50b4512dbf1a78f3c1af6d3abb0', // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: [
        'AMap.scale', 
        'AMap.ToolBar',
        'AMap.DragRoute'
      ],
    })
      .then(AMap => {
        const longitude = coordinate.longitude;
        const latitude = coordinate.latitude;

        const map = new AMap.Map('tiny-map', {
          // 设置地图容器id
          viewMode: '2D', // 是否为3D地图模式
          zoom: 11, // 初始化地图级别
          center: [longitude, latitude], // 初始化地图中心点位置
          mapStyle: 'amap://styles/light', // 设置地图的显示样式
          features: ['bg', 'road', 'point', 'building'], // 设置地图显示的底图参考系
        });
        map.addControl(new AMap.ToolBar());
        return map;
      })
      .then(map => {
        map.on('click', (e: any) => {
          setCoordinate({
            longitude: e.lnglat.lng,
            latitude: e.lnglat.lat
          })
        })
      })
      .catch(e => console.warn(e));
  }, []);
  
  return (
    <div id='tiny-map-container' className='tiny-map-container'>
      {`鼠标在地图上获取经纬度：${coordinate.longitude}, ${coordinate.latitude}`}
      {` `}
      <Button 
        type='dashed'
        onClick={() => {
          if (props.cb) {
            props.cb(coordinate);
          }
        }}
      >确定位置</Button>
      <div id='tiny-map' className='map'></div>
    </div>
  )
}
