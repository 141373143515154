import React, { useContext, useState } from 'react';
import { Button, Drawer, Segmented, Space } from 'antd';
import { ThemeContext } from './ThemeContext';
import CoffIcon from '@/utils/CoffIcon';
import { SegmentedValue } from 'antd/es/segmented';
import FramePage from '../FramePage';
import SmartButton from './SmartButton';

interface ValueI {
  label: string
  icon: string
  value: number
}

type WinType = Window | null

type SegmentedPropType = {
  changeTopMenu: (value: SegmentedValue) => void
}

export type { SegmentedValue } from 'antd/es/segmented';

export default function MySegmented(props: SegmentedPropType) {
  const [winState, setWinState] = useState(0)
  const themeContext = useContext(ThemeContext)
  const [disabled, setDisabled] = useState(false)
  const [danger, setDanger] = useState(false)

  const values: ValueI[] =
    [
      {
        label: '能源站建设',
        icon: 'faGasPump',
        value: 0,
      },
      {
        label: '能源站平台',
        icon: 'faDesktop',
        value: 1,
      },
      {
        label: '能源站管理',
        icon: 'faSliders',
        value: 2,
      }
    ]

  const labelValueIcon = (element: ValueI) => {
    return {
      label: element.label,
      value: element.value,
      icon: <CoffIcon
        style={{
          width: '20px',
          height: '20px',
          marginRight: '8px',
          marginLeft: '5px',
        }}
        icon={element.icon}
      />
    }
  }

  const translate = (values: ValueI[]) => {
    return values.map(value => labelValueIcon(value))
  }

  const onChange = (value: SegmentedValue) => {
    props.changeTopMenu(value)
  }

  return (
    <>
      <Segmented
        style={{
          backgroundColor: themeContext.bgc,
        }}
        options={
          translate(values)
        }
        onChange={onChange}>

      </Segmented>
    </>
  )
};