import {
  IconDefinition,
  faMask,
  faUser,
  faHouse,
  faMessage,
  faEnvelope,
  faGripVertical,
  faChartColumn,
  faWrench,
  faSlidersH,
  faSliders,
  faGripLinesVertical,
  faVoicemail,
  faDesktop,
  faMicrochip,
  faClose,
  faArrowLeft,
  faArrowRight,
  faGear,
  faArrowRightFromBracket,
  faCircleHalfStroke,
  faMapPin,
  faGasPump,
  faIndustry,
  faFireFlameSimple,
  faHammer,
  faSquare,
  faChargingStation,
  faMapLocationDot,
  faLocationDot,
  faListOl,
  faRoute,
  faLaptop,
  faLaptopCode,
  faChartPie,
  faCity,
} from '@fortawesome/free-solid-svg-icons'

export const coffIconNameMap: Record<string, IconDefinition> = {
  'faMask': faMask,
  'faUser': faUser,
  'faHouse': faHouse,
  'faMessage': faMessage,
  'faEnvelope': faEnvelope,
  'faGripVertical': faGripVertical,
  'faChartColumn': faChartColumn,
  'faWrench': faWrench,
  'faSlidersH': faSlidersH,
  'faSliders': faSliders,
  'faGripLinesVertical': faGripLinesVertical,
  'faVoicemail': faVoicemail,
  'faDesktop': faDesktop,
  'faMicrochip' : faMicrochip,
  'faClose': faClose,
  'faArrowLeft': faArrowLeft,
  'faArrowRight': faArrowRight,
  'faGear': faGear,
  'faArrowRightFromBracket': faArrowRightFromBracket,
  'faCircleHalfStroke': faCircleHalfStroke,
  'faMapPin': faMapPin,
  'faGasPump': faGasPump,
  'faIndustry': faIndustry,
  'faFireFlameSimple': faFireFlameSimple,
  'faHammer': faHammer,
  'faSquare': faSquare,
  'faChargingStation': faChargingStation,
  'faMapLocationDot': faMapLocationDot,
  'faLocationDot': faLocationDot,
  'faListOl': faListOl,
  'faRoute': faRoute,
  'faLaptop': faLaptop,
  'faLaptopCode': faLaptopCode,
  'faChartPie': faChartPie,
  'faCity': faCity,
}

export default function iconData(iconName: string): IconDefinition {
  return coffIconNameMap[iconName]
}