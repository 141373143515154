import React, { useContext } from 'react'
import { ThemeContext } from './Home/ThemeContext'

type FrameProps = {
  src: string
}

export default function FramePage(props: FrameProps) {
  const themeContext = useContext(ThemeContext)

  return (
    <iframe
      title='数据大屏'
      // sandbox='allow-forms allow-scripts allow-same-origin allow-popups allow-downloads'
      src={props.src}
      style={{
        height: '100%',
        width: '100%',
        border: 'medium none',
      }}
    ></iframe>
  )
}