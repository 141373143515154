import React, { CSSProperties } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import iconSolidData from './IconSolidData'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

type IconPrefix = "fas" | "far" | "fal" | "fat" | "fad" | "fab" | "fak" | "fass" ;


export interface inconDataI {
  iconData: (iconName: string) => IconDefinition
}

export interface CoffIconProp {
  icon: string;
  type?: IconPrefix;
  style?: CSSProperties;
}

export default function CoffIcon(props: CoffIconProp) {
  
  const iconDataObj: IconDefinition = iconSolidData(props.icon);
  const style = props.style ?? {
    position: 'relative',
    margin: '0 3px',
    padding: '0 6px',
    // marginRight: '3px',
    // fontSize: '18px',
    // right: '14px',
    // marginLeft: '3px',
    // lineHeight: 2.17,
  }

  return (
    <span style={style}>
      <FontAwesomeIcon icon={iconDataObj}/> 
    </span>
  )
}
