import React from 'react'
import { UserOutlined, HolderOutlined } from '@ant-design/icons';
import { Avatar, Badge, Typography } from 'antd'
import CoffIcon from '@/utils/CoffIcon';
import MySettingModel from './MySettingModel';

const { Title } = Typography;

type ProfilePropsType = {
  changeDarkTheme: (checked: boolean, event:React.MouseEvent<HTMLButtonElement>) => void
}

export default class MyProfile extends React.Component<ProfilePropsType> {
  constructor(props: ProfilePropsType) {
    super(props)
  }

  state = {
    isShowModel: false,
  }

  showModel = () => {
    console.log(`showModel`)
    this.setState({
      isShowModel: true
    })
  }

  hideModel = () => {
    console.log(`hideModel`)
    this.setState({
      isShowModel: false
    })
  }

  timer: NodeJS.Timeout | undefined = undefined;

  render() {
    return (
      <>
        <span
          className='home-layout-header-content-profile-trigger'
        >
          <CoffIcon
            icon='faEnvelope'
            type='far'
            style={{
              position: 'absolute',
              fontSize: '18px',
              left: '7px',
              lineHeight: 2.17,
            }}
          />
          <Badge.Ribbon
            style={{
              position: 'absolute',
              top: '-5px',
            }}
            text="32">
            <Title
              ellipsis={true}
              level={4}
              className='home-layout-header-content-profile-message'
            >
              全部监控数据正常！
            </Title>
          </Badge.Ribbon>
        </span>

        <span
          className='home-layout-header-content-profile-setting'
          onMouseEnter={e => {
            // this.showModel()
            clearTimeout(this.timer)
            this.timer = setTimeout(this.showModel, 300);
          }}
          onMouseLeave={e => {
            // this.hideModel()
            clearTimeout(this.timer)
            this.timer = setTimeout(this.hideModel, 500)
          }}
        >
          {this.state.isShowModel && (
            
            <MySettingModel
              changeDarkTheme={this.props.changeDarkTheme}
            ></MySettingModel>
          )}
          <Avatar
            style={{
              position: 'absolute',
              top: '4px',
              left: '4px'
            }}
            shape="square"
            icon={<UserOutlined />}
          />
          {/* <CoffIcon
          iconName='faGripVertical'
          type='fas'
          style={{
            position: 'absolute',
            right: '7px',
            fontSize: '18px',
            lineHeight: 2.17,
            color: '#666'
          }}
          /> */}
          <HolderOutlined
            style={{
              position: 'absolute',
              right: '4px',
              bottom: '2px',
              top: '2px'
            }}
          />
        </span>
      </>
    )
  }
}
