import React, { useContext } from 'react'
import { Layout } from 'antd'
import logo from '@/assets/zhou-jia.png'
import { ThemeContext } from './ThemeContext'
import MySegmented from './MySegmented'
import MyProfile from './MyProfile'

const { Header } = Layout


export default function MyHeader(props: any) {
  const themeContext = useContext(ThemeContext);

  const title = props.title || '智慧能源';

  return (
    <Header
      id='home-layout-header'
      style={{ backgroundColor: themeContext.bgc }}>
      <div className='home-layout-header-bg'></div>
      <div className='home-layout-header-content'>
        <span className='home-layout-header-content-slogan'>
          <span className='home-layout-header-content-img'>
            <img src={logo} alt='logo' width={36} height={36} />
          </span>
          <span className='home-layout-header-content-title'>
            {title}
          </span>
        </span>

        <span>
          <MySegmented 
            changeTopMenu={props.changeTopMenu}
          />
        </span>

        <span className='home-layout-header-content-profile'>
          <MyProfile
            changeDarkTheme={props.changeDarkTheme}
          />
        </span>
      </div>
    </Header>
  )
} 