import React, { useContext, useState } from 'react'
import {
  Menu,
  MenuProps,
  Layout
} from 'antd';


import { ThemeContext } from './ThemeContext';
import MyTrigger from './MyTrigger';


type SiderPropsType = {
  items: MenuProps['items']
}

type CollapseType = 'clickTrigger' | 'responsive';

export default function MySider(props: SiderPropsType) {
  const [collapsed, setCollapsed] = useState(true);

  const themeContext = useContext(ThemeContext);

  const { Sider } = Layout;

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e);
  };
  const onSelect: MenuProps['onSelect'] = (e) => {
    console.log('select ', e);

  }

  const collapseChange = (collapsed: boolean, type: CollapseType) => {
    console.log(`collapseChange type is:${type}`);
    setCollapsed(collapsed);
  }

  return (
    <Sider
      style={{ backgroundColor: themeContext.bgc }}
      className='home-layout-sider'
      collapsible
      defaultCollapsed
      collapsed={collapsed}
      breakpoint={'xxl'}
      theme='light'
      onCollapse={collapseChange}
      trigger={<MyTrigger collapsed={collapsed}/>}
    >
      <Menu
        subMenuOpenDelay={.3}
        style={{ backgroundColor: themeContext.bgc }}
        className='home-layout-sider-menu'
        onClick={onClick}
        onSelect={onSelect}
        defaultSelectedKeys={['1']}
        mode="inline"
        items={props.items}
      />
    </Sider>
  )
}