import React, { useEffect, useState } from 'react'

import {
  ConfigProvider,
  theme,
  MenuTheme,
  Layout,
  MenuProps
} from 'antd';
import './index.less';
import MyBreadcrumb from './MyBreadcrumb';
import MyHeader from './MyHeader';
import MySider from './MySider';
import MyContent from './MyContent';
import MyFooter from './MyFooter';
import { ThemeContext, themes } from './ThemeContext';
import { MenusDataType } from './menusData';
import { SegmentedValue } from './MySegmented';
import {
  PieChartOutlined,
  DesktopOutlined,
  UserOutlined,
  TeamOutlined,
  FileOutlined,
  CompassOutlined,
  NodeIndexOutlined,
  SnippetsOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CoffIcon from '@/utils/CoffIcon';

function _initMenusDatas(menusData: MenusDataType) {
  
}

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  path?: string,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    path,
    children,
    type,
    label: children ? label : (<Link to={path!}>{label}</Link>),
  } as MenuItem;
}

export default function Home() {

  const [menuTheme, setMenuTheme] = useState<MenuTheme>('light');

  const [themeContextValue, setThemeContextValue] = useState(themes.light);

  const [topMenuIndex, setTopMenuIndex] = useState(0);

  useEffect(() => {
    
  })

  const monitorEItems: MenuProps['items'] = [
    getItem('地图总览', '1', <CoffIcon icon={'faMapLocationDot'}/>, '/total-data'),
    getItem('长治市规划', '6', <CoffIcon icon={'faCity'}/>, '/changzhi'),
    getItem('加氢站管理', '2', <CoffIcon icon={'faGasPump'}/>, '/hydrogens'),
    getItem('地点管理', '3', <CoffIcon icon={'faLocationDot'}/>, '/locations'),
    getItem('地点分类', '4', <CoffIcon icon={'faListOl'}/>, '/location-types'),
    getItem('路径规划分析', '5', <CoffIcon icon={'faRoute'}/>, '/analysis'),
  ];

  const itemEquipment: MenuProps['items'] = [
    getItem('上海陆家嘴中心', '1', <CoffIcon icon={'faCity'}/>, '/sh-lujiazui'),
    getItem('设备管理', '2', <PieChartOutlined />, '/equip2'),
    getItem('设备测点管理', '3', <DesktopOutlined />, '/equip3'),

    getItem('智慧能源管理', 'sub1', <UserOutlined />, '/equipsub1', [
      getItem('实时数据管理', '4',<FileOutlined />,  '/equipsub1/equip4'),
      getItem('智慧终端控制', '5',<FileOutlined />,  '/equipsub1/equip5'),
      getItem('数据报表', '6',<FileOutlined />,  '/equipsub1/equip6'),
      getItem('数据分析', '7',<FileOutlined />,  '/equipsub1/equip7'),
    ]),
    getItem('设备保养检修', 'sub2', <TeamOutlined />, '/equipsub2', [
      getItem('设备保养管理', '7',<FileOutlined />,  '/equipsub2/equip7'),
      getItem('设备检修管理', '8',<FileOutlined />,  '/equipsub2/equip8')
    ]),
  
    getItem('设备巡检管理', 'sub3', <FileOutlined /> , '/equipsub3',[
      getItem('巡检记录', '9',<FileOutlined />,  '/equipsub3/equip9'),
      getItem('巡检点管理', '10',<FileOutlined />,  '/equipsub3/equip10'),
      getItem('巡检线管理', '11',<FileOutlined />,  '/equipsub3/equip11')
    ]),
  ]
  
  const workItems: MenuProps['items'] = [
    getItem('能源站平台', 'sub1', <CoffIcon icon={'faDesktop'}/>, '/worksub1', [
      getItem('全国已建加氢站', 'sub12', <CoffIcon icon={'faLaptopCode'}/>,  '/worksub1/work2'),
      getItem('海德利森加氢站', 'sub13', <CoffIcon icon={'faLaptopCode'}/>, '/worksub1/work3'),
      getItem('氢产业基础数据', 'sub14', <CoffIcon icon={'faLaptopCode'}/>, '/worksub1/work4'),
    ]),
    getItem('能源站数据', '1', <CoffIcon icon={'faChartPie'} />, '/work1', [
      getItem('长申潞宝综合站', '333', <CoffIcon icon={'faChartColumn'} />, '/work1/work3'),
      getItem('绿地发电站', '111', <CoffIcon icon={'faChartColumn'} />, '/work1/work1'),
      getItem('八字山发电站', '112', <CoffIcon icon={'faChartColumn'} />, '/work1/work2')
    ]),
    // getItem('定期工作', 'sub2', <UserOutlined />, '/worksub2', [
    //   getItem('定期切换', '4', <UserOutlined />, '/worksub2/work4'),
    //   getItem('定期试验', '5', <UserOutlined />, '/worksub2/work5'),
    //   getItem('工作模板', '6', <UserOutlined />, '/worksub2/work6'),
    // ]),
    // getItem('其他', 'sub3', <TeamOutlined />, '/worksub3',
    //   [getItem('其他 1', '7', <UserOutlined />, '/worksub3/work7'),
    //   getItem('其他 2', '8', <UserOutlined />, '/worksub3/work8')
    // ]),
  ];

  const topMenus = [monitorEItems, workItems, itemEquipment];

  const { defaultAlgorithm, darkAlgorithm, compactAlgorithm } = theme;

  const changeDarkTheme = (checked: boolean, event:React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setThemeContextValue(checked ? themes.dark : themes.light);
    setMenuTheme(checked ? 'dark' : 'light')
  }

  const changeTopMenu = (menuIndex: SegmentedValue) => {
    console.log(`menuIndex is: ${menuIndex}`);
    setTopMenuIndex(menuIndex as number);
  }

  return (
    <ConfigProvider
      theme={{ algorithm: menuTheme === 'dark' ? darkAlgorithm : defaultAlgorithm }}
    >
      <ThemeContext.Provider value={themeContextValue}>
        <Layout>
          <MyHeader 
            title={'周佳氢能管理平台'} 
            changeTopMenu={changeTopMenu}
            changeDarkTheme={changeDarkTheme}
          >
          </MyHeader>
          <Layout id='home-layout-bg'>
            <MySider
              items={topMenus[topMenuIndex]}
            ></MySider>
            <Layout 
              className='home-layout-bg-ly'
              style={{ backgroundColor: themeContextValue.bgc}}
            >
              <MyBreadcrumb></MyBreadcrumb>
              <ConfigProvider
                theme={{
                  algorithm: menuTheme === 'dark'
                    ? [darkAlgorithm, compactAlgorithm]
                    : [defaultAlgorithm, compactAlgorithm]
                }}
              >
                <MyContent></MyContent>
                {/* <MyFooter ></MyFooter> */}
              </ConfigProvider>
            </Layout>
          </Layout>
        </Layout>
      </ThemeContext.Provider>
    </ConfigProvider>
  )
}
