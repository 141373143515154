import '../CFMap/prefixScript.js';
import React, { useContext, useEffect, useState } from 'react'
import './index.less';
import AMapLoader from '@amap/amap-jsapi-loader';
import Request from '@/utils/Request';
import { ThemeContext } from '../Home/ThemeContext';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
declare class Loca {};

interface DataType {
  id: string;
  name: string;
  key: string;
  longitude: number;
  latitude: number;
  type: {
    id: string;
    name: string;
    color: string;
  };
  district: {
    adCode: string;
    name: string;
  };
}

const TotalData: React.FC = () => {
  const themeContext = useContext(ThemeContext)

  const [adCode, setAdCode] = useState<string>('');
  const [typeId, setTypeId] = useState<string[]>([]);
  const [data, setData] = useState<DataType[]>([]);

  useEffect(() => {
    console.log('total map useEffect');
    AMapLoader.load({
      key: '5532c50b4512dbf1a78f3c1af6d3abb0', // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      Loca: {
        version: '2.0.0', // Loca 版本号
      },
      plugins: [
        'AMap.scale', 
        'AMap.HawkEye',
        'AMap.ToolBar',
        'AMap.ControlBar',
        'AMap.DragRoute'
      ],
    })
      .then(AMap => {
        const map = new AMap.Map('total-data-map', {
          viewMode: '2D',
          zoom: 5,
          center: [112.578781, 37.813948], // 初始化地图中心点位置
          mapStyle: 'amap://styles/grey', // 设置地图的显示样式
          features: ['bg', 'road', 'point', 'building'], // 设置地图显示的底图参考系
          showBuildingBlock: true
        });
        map.addControl(new AMap.ToolBar());
        map.addControl(new AMap.ControlBar());  
        if (data && data.length > 0) {
          data.forEach((item: DataType) => {
            const circleMarker = new AMap.CircleMarker({
              name: item.name,
              center: [item.longitude, item.latitude],
              fillColor: item.type.color,
              radius: 6,
              fillOpacity:0.5,
              bubble:true,
              strokeOpacity:0.5,
              extData: item.id
            });
            circleMarker.setMap(map);
          });
        }

        // const layer = new (Loca as any).PointLayer({
        //   map: map
        // });

        // layer.setData(data, {
        //   lnglat: (obj: DataType) => {
        //     console.log(`name is:${obj.name}`)
        //     return [obj.longitude, obj.latitude];
        //   }
        // });
        // layer.setOptions({
        //   unit: 'px',
        //   style: {
        //     borderColor: '#14B4C9',
        //     borderWidth: 0.5,
        //     radius: 1,
        //     color: 'rgba(42, 167, 184, 1)',
        //   }
        // });
        // layer.render();
      })
    .catch(e => console.error(`发生了错误, ${e}`))
  }, [data])

  useEffect(() => {
    console.log('request data useEffect');
    let path = `/hydrogen-sites/all?`;
    if (adCode && adCode.length > 0) {
      path += `districtAdcode=${adCode}`;
    }
    if (typeId && typeId.length > 0) {
      path += `&typeId=${typeId.join(',')}`;
    }
    Request('get', path, {})
      .then((jsonRes: any) => {
        if (jsonRes && jsonRes.success) {
          return jsonRes.data;
        }
      })
      .then(data => setData(data))
      .catch(e => console.error(e));
  }, [adCode, typeId]);
  
  return (
    <div id='total-data-main'>
      <div id="total-data-map" className="map"></div>
    </div>
  )
}

export default TotalData
