import React from 'react';

export const themes = {
  light: {
    dpc: '#fff',
    bgc: '#f2f2f2',
    hvc: '#dcdcdc',
    mhvc: '#cfcfcf',
    key: 'light',
  },
  dark: {
    dpc: '#000',
    bgc: '#141414',
    hvc: '#2a2a2a',
    mhvc: '#444444',
    key: 'dark',
  },
};

export const ThemeContext = React.createContext(
  themes.dark
);