import React from 'react'
import './index.less'

export interface InfoPanelProps {
  licheng1: number;
  licheng2: number;
  licheng3: number;
  licheng4: number;
  licheng5: number;
  licheng6: number;
  licheng7: number;
}

export default function InfoPanel(props: InfoPanelProps) {
  return (
    <div className='info-panel'>
      <div className='info-panel-item'>
        <span className='info-panel-title'>华电包头电厂加氢站</span>
        <span className='info-panel-detail'>：{props.licheng1 / 1000}公里</span>
      </div>
      <div className='info-panel-item'>
        <span className='info-panel-title'>华电东华电厂加氢站</span>
        <span className='info-panel-detail'>：{props.licheng2 / 1000}公里</span>
      </div>
      <div className='info-panel-item'>
        <span className='info-panel-title'>华电土右电厂德萨线加氢站</span>
        <span className='info-panel-detail'>：{props.licheng3 / 1000}公里</span>
      </div>
      <div className='info-panel-item'>
        <span className='info-panel-title'>华电包头电厂撬装站</span>
        <span className='info-panel-detail'>：{props.licheng4 / 1000}公里</span>
      </div>
      <div className='info-panel-item'>
        <span className='info-panel-title'>华电钢铁深加工园区加氢站</span>
        <span className='info-panel-detail'>：{props.licheng5 / 1000}公里</span>
      </div>
      <div className='info-panel-item'>
        <span className='info-panel-title'>华电大城西加氢站</span>
        <span className='info-panel-detail'>：{props.licheng6 / 1000}公里</span>
      </div>
      <div className='info-panel-item'>
        <span className='info-panel-title'>华电大城西煤炭物流园撬装站</span>
        <span className='info-panel-detail'>：{props.licheng7 / 1000}公里</span>
      </div>
      <div>
        <span className='info-panel-title'>总距离</span>
        <span className='info-panel-detail'>：{(props.licheng1 + props.licheng2 + props.licheng3 + props.licheng4 + props.licheng5 + props.licheng6 + props.licheng7) / 1000}公里</span>
      </div>
    </div>
  )
}
